<template>
  <header>
    <template v-if="currentPage.name === 'home'">
      <div class="left-side">
        <WfOptionsMenu />
      </div>
    </template>
    <button v-else class="menu" @click="setCurrentPage({ name: 'home', params: {} })">
      <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
        <path
          clip-rule="evenodd"
          d="m13.707 16 4.6465-4.6464-.7071-.7071-5.3536 5.3535 5.3536 5.3536.7071-.7071z"
          fill="#000"
          fill-rule="evenodd"
        />
      </svg>
    </button>

    <button
      v-if="currentSelection != null"
      class="button button--primary"
      :class="{ 'button--success': copiedSuccessfully }"
      :disabled="isCopyButtonLoading"
      @click="handleCopy()"
    >
      <template v-if="!isCopyButtonLoading">
        <template v-if="!copiedSuccessfully">Copy to Webflow</template>
        <template v-else>Copied!</template>
      </template>
      <template v-else>Copying...</template>
    </button>
    <template v-else>
      <template v-if="!isCopyButtonLoading">
        <template v-if="copiedSuccessfully">
          <button
            class="button button--primary"
            :class="{ 'button--success': copiedSuccessfully }"
            :disabled="isCopyButtonLoading"
          >
            Copied!
          </button>
        </template>
      </template>
      <template v-else>
        <button
          class="button button--primary"
          :class="{ 'button--success': copiedSuccessfully }"
          :disabled="isCopyButtonLoading"
        >
          Copying...
        </button>
      </template>
    </template>
  </header>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { dispatch, handleEvent } from '../figma/ui-message-handler';

import WfOptionsMenu from '@/components/WfOptionsMenu.vue';

export default {
  components: {
    WfOptionsMenu,
  },
  props: ['components'],
  data() {
    return {
      json: null,
    };
  },
  computed: {
    ...mapState(['currentPage', 'user', 'currentSelection', 'isCopyButtonLoading', 'copiedSuccessfully']),
  },
  methods: {
    ...mapMutations(['setCopyButtonLoading', 'setCurrentPage', 'setModal']),

    async handleCopy() {
      this.setModal({ show: false });
      this.setCopyButtonLoading(true);
      dispatch('handleCopy', { id: this.currentSelection, userId: this.user.id });
      const hasVectorsOrNonAutoLayout = await new Promise((resolve) =>
        handleEvent('hasVectorsOrNonAutoLayoutNodes', (value) => resolve(value))
      );
      if (hasVectorsOrNonAutoLayout == null) return;
      if (hasVectorsOrNonAutoLayout.type === 'vector') {
        this.setModal({
          show: true,
          data: {
            video_id: 'detach-instance.mov',
            paragraphs: [
              'It looks like you have one or more INSTANCES (empty purple diamond) nodes. <strong>Detach instances</strong> (⌥⌘B) and try again.',
              'You can <a href="https://docs.google.com/document/d/1JjEhDugqVtvQ4pCGaXtM9CXvlT5g5vjlyIDcqIpjEGY/edit?pli=1#heading=h.opqfz6d9rnsb" target="_blank">learn more here</a>.',
            ],
          },
        });

        this.setCopyButtonLoading(false);
        return;
      } else if (hasVectorsOrNonAutoLayout.type === 'non-auto-layout') {
        this.setModal({
          show: true,
          data: {
            video_id: 'add-auto-layout.mov',
            paragraphs: [
              '<strong>Use only auto layout Frames</strong>. It looks like you have Frames that are not auto layout.',
              'To make Frames auto layout, select them and hit "Shift" + "A" (or click the "+" icon next to Auto layout in the Inspector panel). <strong>Don\'t forget to check the padding</strong>.',
            ],
          },
        });

        this.setCopyButtonLoading(false);
        return;
      }
    },
  },
};
</script>
