<template>
  <div class="wf-select-frame wf-success-screen">
    <WfIcon icon="successfull-sync" />
    <div class="wf-success-screen-message">
      <strong>Ready to import</strong>
      Open your site in Webflow and launch the Figma to Webflow app to import.
    </div>
  </div>
</template>
<script>
import WfIcon from '@/components/WfIcon.vue';

export default {
  components: {
    WfIcon,
  },
};
</script>
