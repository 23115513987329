<template>
  <div class="wf-main-page">
    <WfMainTabs />
    <main :class="{ 'is-scrollable': selectedTab === 'add-layout' }">
      <WfCopyPastePage v-if="selectedTab === 'copy-paste'" />
      <WfAddLayoutPage v-else-if="selectedTab === 'add-layout'" />
      <WfDesignSystem v-else-if="selectedTab === 'design-system'" />
    </main>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { dispatch, handleEvent } from '../figma/ui-message-handler';

import WfCopyPastePage from '@/pages/WfCopyPastePage.vue';
import WfMainTabs from '@/components/new-layout/WfMainTabs.vue';
import WfDesignSystem from '@/components/new-layout/WfDesignSystem.vue';
import WfAddLayoutPage from '@/components/new-layout/WfAddLayoutPage.vue';

export default {
  components: {
    WfMainTabs,
    WfCopyPastePage,
    WfDesignSystem,
    WfAddLayoutPage,
  },
  props: ['structureComponents', 'layoutComponents'],
  mounted() {
    const defaultSiteId = 'null'; // must be a string
    dispatch('getRootPropertyValue', { prop: 'selectedSiteId', value: defaultSiteId });
    handleEvent('selectedSiteId', (siteId) => {
      if (this.userSites == null) {
        this.setSelectedSiteId(null);
        return;
      }
      // Check if the selected site is authorized
      const isAuthorizedSite = this.userSites.some((site) => site.id === siteId);
      // If it's not, make it null
      const selectedSiteId = !isAuthorizedSite ? null : siteId;
      // If it's authorized, use it.
      this.setSelectedSiteId(selectedSiteId);
    });
  },
  computed: {
    ...mapState(['currentSelection', 'userSites', 'selectedTab']),
  },
  methods: {
    ...mapMutations(['setSelectedSiteId', 'setIsScrolling']),

    onScroll(e, position) {
      const isScrolling = position.scrollTop > 10;
      this.setIsScrolling(isScrolling);
    },
  },
};
</script>
