<!-- eslint-disable vue/valid-template-root -->
<template></template>

<script>
import * as entities from 'entities';
import { mapMutations, mapState } from 'vuex';
import { trackEvent, COPY_INITIATED } from '@/helpers/analytics';
import { handleEvent } from '../figma/ui-message-handler';
export default {
  mounted() {
    handleEvent('setCurrentSelection', (value) => this.setCurrentSelection(value));
    handleEvent('copyToClipboard', (json) => this.copyToClipboard(json));
    document.addEventListener('copy', this.copyEventHandler);
  },
  beforeUnmount() {
    document.removeEventListener('copy', this.copyEventHandler);
  },
  computed: {
    ...mapState(['clipboardData']),
  },
  methods: {
    ...mapMutations([
      'setCurrentSelection',
      'setCopiedSuccessfully',
      'setCopyButtonLoading',
      'setClipboardData',
      'setNeedsToConfirmCopy',
    ]),

    async copyToClipboard(json) {
      if (json === '') {
        this.setCopyButtonLoading(false);
        return;
      }

      this.encodeJson(json);
      if (document && document.body) {
        const input = document.createElement('input');
        document.body.appendChild(input);
        input.value = '';
        input.focus();
        input.select();
        const success = document.execCommand('Copy');
        input.remove();

        if (!success) {
          this.setNeedsToConfirmCopy(true);
        }
      }
    },
    copyEventHandler(e) {
      e.clipboardData.setData('text/html', this.clipboardData);

      trackEvent(COPY_INITIATED);
      this.setCopyButtonLoading(false);
      this.setCopiedSuccessfully(true);
      setTimeout(() => this.setCopiedSuccessfully(false), 4000);
      e.preventDefault();
    },
    encodeJson(json) {
      const encodedJson = entities.encodeHTML(json);
      this.setClipboardData(this.getHtmlString(encodedJson));
    },
    getHtmlString(json) {
      return `<p xmlns="http://www.w3.org/1999/xhtml"><main data-clipboard="${json}" data-clipboard-source="figma-plugin"></main></p>`;
    },
  },
};
</script>
