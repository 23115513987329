<!-- eslint-disable vue/valid-template-root -->
<template></template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { dispatch, handleEvent } from '../figma/ui-message-handler';
import md5 from 'md5';

export default {
  mounted() {
    handleEvent('getImageHash', (bytes) => this.getImageHashForSvg(bytes));
    handleEvent('storeImages', (images) => this.storeImagesFromBytes(images));
    handleEvent('storeComponentsThumbnails', (thumbnails) => this.storeComponentsThumbnails(thumbnails));
    handleEvent('getArrayBufferFromImage', (imageUrl) => this.handleArrayBufferFromImage(imageUrl));
  },
  computed: {
    ...mapState(['user', 'userInfo', 'token', 'selectedSiteId']),
  },
  methods: {
    ...mapMutations(['setCopyButtonLoading', 'setHasImagesToCopy']),
    ...mapActions(['uploadImagesToS3Bucket']),

    async handleArrayBufferFromImage(imageUrl) {
      const request = new XMLHttpRequest();
      request.open('GET', imageUrl);
      request.responseType = 'arraybuffer';
      request.setRequestHeader('Content-Type', 'application/xml');

      request.onload = async () => {
        const arrayBufferView = await new Uint8Array(request.response);
        dispatch('setArrayBufferFromImage', arrayBufferView);
      };
      request.send();
    },
    async storeImagesFromBytes(images) {
      const MAX_IMAGES = 30;
      if (images.length < 1) {
        dispatch('imageSavedSuccessfully', []);
        return;
      }

      if (images.length >= MAX_IMAGES) {
        this.dispatchMaxImageUpload(MAX_IMAGES);
        return;
      }

      this.setHasImagesToCopy(true);
      let hashes = [];
      let imagesNames = [];
      let imagesSizes = [];
      const payload = new FormData();
      for (const image of images) {
        if (this.isImageHashUnique(image.imageHash, hashes)) {
          const blob = new Blob([new Uint8Array(image.bytes).buffer], {
            type: image.ext === 'png' ? 'image/png' : 'image/svg+xml',
          });

          // Size limit check
          const imageSizeInMB = (blob.size / (1024 * 1024)).toFixed(2);
          imagesSizes.push(Number(imageSizeInMB));
          const imagesTotalSize = imagesSizes.reduce((a, b) => a + b);
          // Check if Total Images Size is under the limit of 4.5MB
          if (imagesTotalSize > 4.5) {
            const errorMsg =
              '❌ Images size is too large (> 4.5 MB). Copy smaller sections of your site. Please try again.';
            this.dispatchError(errorMsg);
            return;
          }

          // Ref: payload.append(name, value, filename)
          payload.append(image.imageHash, blob, image.name);
          imagesNames.push(image.imageHash);
          hashes.push(image.imageHash);
        }
      }

      payload.append('names', JSON.stringify(imagesNames));
      payload.append('siteId', this.selectedSiteId);
      payload.append('write', this.token);
      const imagesUrlAndAssetId = await this.uploadImagesToS3Bucket(payload);
      if (imagesUrlAndAssetId === 'ERROR_UPLOADING') {
        const errorMsg = '❌ Something went wrong uploading images to our server. Please try again or contact us.';
        this.dispatchError(errorMsg);
        return;
      }
      if (imagesUrlAndAssetId === 'MAX_SIZE') {
        const errorMsg =
          '❌ Images size is too large (> 4.5 MB). Copy smaller sections of your site. Please try again.';
        this.dispatchError(errorMsg);
        return;
      }
      if (imagesUrlAndAssetId === 'MAX_IMAGES') {
        this.dispatchMaxImageUpload(MAX_IMAGES);
        return;
      }

      dispatch('imageSavedSuccessfully', imagesUrlAndAssetId);
      this.setHasImagesToCopy(false);
    },

    isImageHashUnique(hash, array) {
      return !array.some((item) => hash === item);
    },
    getImageHashForSvg(bytes) {
      const decode = this.decodeUint8Array(bytes);
      let svgString = decode.replace(/\n/g, '');

      // SVGs are being duplicated because the svg string is always different - specifically its IDs.
      // Solution: Parse the svg string and replace all IDs with incremental integers.
      const ids = svgString.matchAll(/id="(.*?)"/g);
      const idsArray = Array.from(ids, (x) => x[1]);
      idsArray.forEach((id, i) => (svgString = svgString.replaceAll(id, (i + 1).toString())));

      // Hash the SVG
      const hash = md5(svgString);
      dispatch('setImageHash', hash);
    },
    decodeUint8Array(bytes) {
      return new TextDecoder().decode(bytes);
    },
    areEqual(buf1, buf2) {
      if (buf1.byteLength != buf2.byteLength) return false;
      var dv1 = new Int8Array(buf1);
      var dv2 = new Int8Array(buf2);
      for (var i = 0; i != buf1.byteLength; i++) {
        if (dv1[i] != dv2[i]) return false;
      }
      return true;
    },
    dispatchMaxImageUpload(max) {
      dispatch('imageSavedSuccessfully', 'MAX_SIZE');
      dispatch(
        'notify',
        `❌ You are copying more than ${max} images (including icons). Copy smaller sections, one at a time.`
      );
      this.setCopyButtonLoading(false);
    },
    dispatchError(errorMsg) {
      dispatch('imageSavedSuccessfully', 'MAX_SIZE');
      dispatch('notify', errorMsg);
      this.setCopyButtonLoading(false);
    },
  },
};
</script>
