<template>
  <section class="selection" :class="{ 'block-user-interaction': isCopyButtonLoading }">
    <div class="icon-container flex-center">
      <WfIcon :icon="copiedSuccessfully ? 'copied-successfully' : 'selected-element'" />
    </div>
    <div v-if="copiedSuccessfully" class="selection-content">
      <div class="empty-state">
        <div class="type type--small"><strong>Copied to clipboard!</strong></div>
        <div class="type type--small">Now you can paste it in your Webflow project</div>
      </div>
    </div>
    <div v-else class="selection-content">
      <div v-if="currentSelection == null" class="empty-state">
        <div class="type type--small"><strong>Select a frame to copy</strong></div>
        <div class="type type--small">Select an auto layout or text frame to copy</div>
      </div>
      <WfSetHtmlTag v-if="currentSelection != null" />
      <WfSetResponsiveUi v-if="showResponsiveUi" />
    </div>
  </section>
</template>
<script>
import { mapState } from 'vuex';
import WfIcon from '@/components/WfIcon.vue';
import WfSetHtmlTag from '@/components/ui/WfSetHtmlTag.vue';
import WfSetResponsiveUi from '@/components/ui/WfSetResponsiveUi.vue';
export default {
  components: {
    WfIcon,
    WfSetHtmlTag,
    WfSetResponsiveUi,
  },
  computed: {
    ...mapState(['currentSelection', 'copiedSuccessfully', 'isCopyButtonLoading']),

    showResponsiveUi() {
      const isFrame = this.currentSelection?.type === 'FRAME';
      const isHorizontal = this.currentSelection?.layoutMode === 'HORIZONTAL';
      const isWide = this.currentSelection?.width >= 239;
      return this.currentSelection != null && isFrame && isHorizontal && isWide;
    },
  },
};
</script>
