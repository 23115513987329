<template>
  <div class="wf-fixed-banner">
    <div class="icon-wrapper">
      <WfIcon icon="question-mark" />
    </div>
    <div class="message">
      <div class="text">{{ message }}</div>
      <button class="close-button" @click="setBannerVisibility(false)">Got it</button>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import WfIcon from '@/components/WfIcon.vue';

defineProps(['message']);
const store = useStore();
const setBannerVisibility = store.commit.setBannerVisibility;
</script>
