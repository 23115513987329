import axios from 'axios';
import headers from './headers';

export const getCode = async (data) => {
  const URL = `get-webflow-code`;
  return await axios.post(URL, data, { headers });
};

export const getAccessToken = async (data) => {
  const URL = `get-webflow-access-token`;
  return await axios.post(URL, data, { headers });
};

export const getUser = async (data) => {
  const URL = `get-webflow-user`;
  return await axios.post(URL, data, { headers });
};

export const getInfo = async (data) => {
  const URL = `get-webflow-info`;
  return await axios.post(URL, data, { headers });
};

export const revokeToken = async (data) => {
  const URL = `revoke-webflow-token`;
  return await axios.post(URL, data, { headers });
};

export const storePreBuiltComponentJson = async (data) => {
  const URL = `store-pre-built-components`;
  return await axios.post(URL, data, { headers });
};
