<template>
  <section class="copy-button">
    <div v-if="currentSelection == null" class="button-wrapper">
      <div class="buttons-group">
        <button
          class="button button--primary w-100 flex-center gap-8"
          :class="{ 'button--success': copiedSuccessfully }"
          :disabled="isCopyButtonLoading || isBlocking || (!isCopyButtonLoading && !copiedSuccessfully)"
        >
          <template v-if="!isCopyButtonLoading && !copiedSuccessfully"> Copy to Webflow </template>
          <template v-else-if="copiedSuccessfully">
            <WfIcon icon="checkmark" color="#FFF" />
            Copied to clipboard
          </template>
          <template v-else>
            <span v-if="hasImagesToCopy" class="small-loader"></span>
            <span v-text="hasImagesToCopy ? 'Preparing assets...' : 'Copying...'"></span>
          </template>
        </button>
      </div>
    </div>
    <div v-else class="button-wrapper">
      <div class="buttons-group">
        <button
          v-if="!needsToConfirmCopy"
          ref="copyButton"
          class="button button--primary flex-center"
          :class="{ 'button--success': copiedSuccessfully }"
          :disabled="isCopyButtonLoading || isBlocking || noSiteSelected"
          @click="handleCopy($event)"
        >
          <template v-if="!isCopyButtonLoading">
            <template v-if="!copiedSuccessfully">Copy to Webflow</template>
            <template v-else>
              <WfIcon icon="checkmark" color="#FFF" />
              Copied to clipboard
              <div
                class="wf-tooltip"
                :class="{ 'is-first-time': isFirstTimeOnPlugin }"
                v-html="`Now you can go to your Webflow site <span>${selectedSiteName}</span> and paste.`"
              ></div>
            </template>
          </template>
          <template v-else>
            <span v-if="hasImagesToCopy" class="small-loader"></span>
            <span v-text="hasImagesToCopy ? 'Preparing assets...' : 'Copying...'"></span>
          </template>
          <div v-if="noSiteSelected" class="wf-tooltip no-site-selected">First choose a Webflow site above</div>
        </button>
        <button v-else class="button button--primary flex-center button--confirmation" @click="confirmCopyToWebflow()">
          <span>Confirm copy to Webflow</span>
          <div class="wf-tooltip no-site-selected">
            Click to confirm you want to copy to Webflow. Use the Figma Desktop App to remove this confirmation step.
          </div>
        </button>
      </div>
      <WfWarning
        v-if="hasWarnings && !copiedSuccessfully && !isCopyButtonLoading && !noSiteSelected"
        :is-blocking="isBlocking"
        :message="warning.data.message"
      />
    </div>
  </section>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import WfIcon from '@/components/WfIcon.vue';
import { trackEvent, COPY_INITIATED } from '@/helpers/analytics';
import WfWarning from '@/components/ui/WfWarning.vue';
import { dispatch, handleEvent } from '@/figma/ui-message-handler';
export default {
  components: {
    WfIcon,
    WfWarning,
  },
  computed: {
    ...mapState([
      'currentSelection',
      'copiedSuccessfully',
      'isCopyButtonLoading',
      'hasImagesToCopy',
      'warning',
      'selectedSiteId',
      'needsToConfirmCopy',
      'isFirstTimeOnPlugin',
      'user',
      'userSites',
    ]),

    hasWarnings() {
      return this.warning.type != null;
    },
    isBlocking() {
      if (this.warning.type === null) return false;
      return this.warning.data.isBlocking;
    },
    noSiteSelected() {
      return this.selectedSiteId === null;
    },
    selectedSiteName() {
      return this.userSites.find((site) => site.id === this.selectedSiteId)?.displayName || 'None selected';
    },
  },
  methods: {
    ...mapMutations(['setCopyButtonLoading', 'setIsFirstTimeOnPlugin', 'setNeedsToConfirmCopy', 'setComponentsToSync']),

    async handleCopy() {
      trackEvent(COPY_INITIATED);
      this.setCopyButtonLoading(true);
      dispatch('handleCopy', { id: this.currentSelection.id, userId: this.user.id });
      const hasVectorsOrNonAutoLayout = await new Promise((resolve) =>
        handleEvent('hasVectorsOrNonAutoLayoutNodes', (value) => resolve(value))
      );

      if (hasVectorsOrNonAutoLayout != null) {
        this.setCopyButtonLoading(false);
      }

      // Make sure to show the "paste to Weflow" message
      // only to first time on plugin users
      // Give 4 seconds to change variable
      setTimeout(() => {
        this.handleFirstTimeOnPluginNotification();
      }, 4000);
    },

    confirmCopyToWebflow() {
      if (document && document.body) {
        const input = document.createElement('input');
        document.body.appendChild(input);
        input.value = '';
        input.focus();
        input.select();
        const success = document.execCommand('Copy');
        input.remove();

        if (!success) {
          dispatch('notify', '❌ Number of layers is too large. Please copy smaller sections');
          this.setCopyButtonLoading(false);
        }
      }
      this.setNeedsToConfirmCopy(false);
    },

    // Helpers
    handleFirstTimeOnPluginNotification() {
      if (this.isFirstTimeOnPlugin) {
        this.setIsFirstTimeOnPlugin(false);
        dispatch('setRootPropertyValue', { prop: 'isFirstTimeOnPlugin', value: 'false' });
      }
    },
  },
};
</script>
