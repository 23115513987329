<template>
  <div class="wf-components-to-sync">
    <div v-if="syncedSuccessfully" class="wf-select-components-cta">
      <WfSuccessScreenMessage />
    </div>
    <div
      v-else-if="!$parent.hasInfo || ($parent.hasInfo && $parent.selectedComponents.length === 0)"
      class="wf-select-components-cta"
    >
      <div class="wf-select-frame">
        <template v-if="!$parent.hasOneOrMoreFrameSelected">
          <WfIcon icon="select-component" />
          <br />
          <div>
            <strong>Select items</strong><br /><br />Use Figma's canvas to select components you want to sync (main,
            instances, variants).
          </div>
        </template>
        <template v-else>
          <WfIcon icon="elements-selected" />
          <br />
          <div>
            <strong>Elements selected</strong><br /><br />Now go ahead and retrieve their details before syncing them
            with Webflow.
          </div>
        </template>
      </div>
    </div>
    <div v-else-if="$parent.hasInfo" class="wf-current-selection">
      <div class="wf-select-frame">
        <div class="wf-selected-components-and-variables">
          <div class="selected-row">
            <WfIcon icon="selected-components" />
            <div>{{ $parent.selectedComponents.length }} component{{ isPlural($parent.selectedComponents) }}</div>
          </div>
          <div class="selected-row variables-info">
            <div class="icon-wrapper">
              <WfIcon icon="selected-variables" />
            </div>
            <div :class="{ 'variables-messages': hasParagraphSpacing }">
              <div class="variables-tooltip-wrapper">
                <span>{{ $parent.selectedVariables.length }} variable{{ isPlural($parent.selectedVariables) }}</span>
                <div
                  v-if="$parent.selectedVariables.length > 0"
                  class="icon-wrapper flex-center"
                  id="responsiveTooltip"
                >
                  <WfIcon icon="question-mark" />
                </div>
              </div>
              <div v-if="hasParagraphSpacing">
                <code>paragraph-spacing</code> will be converted to <code>margin-bottom</code> in Webflow
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import tippy from 'tippy.js';
import { mapState, mapGetters } from 'vuex';
import 'tippy.js/dist/tippy.css';
import WfIcon from '@/components/WfIcon.vue';
import { trackEvent, PLUGIN_PAGE_VIEWED } from '@/helpers/analytics';
import WfSuccessScreenMessage from '@/components/WfSuccessScreenMessage.vue';

export default {
  components: {
    WfIcon,
    WfSuccessScreenMessage,
  },
  data() {
    return {
      unsupportedStyleProps: ['paragraph-indent', 'font-weight', 'font-style'],
    };
  },
  mounted() {
    trackEvent(PLUGIN_PAGE_VIEWED, { pageName: 'Design System Sync - Components' });
    this.setupTooltips();
  },
  watch: {
    '$parent.hasInfo': {
      handler() {
        this.$nextTick(() => {
          this.setupTooltips();
        });
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapState(['syncedSuccessfully']),
    ...mapGetters(['selectedWebflowSiteUrlWithApp']),
    hasParagraphSpacing() {
      return this.$parent.selectedVariables.some((v) => v.styleProp === 'paragraph-spacing');
    },
    unsupportedVariablesProps() {
      return this.$parent.selectedVariables.filter((v) => this.unsupportedStyleProps.includes(v.styleProp));
    },
  },
  methods: {
    isPlural(array) {
      return array.length === 1 ? '' : 's';
    },
    setupTooltips() {
      tippy('#responsiveTooltip', {
        maxWidth: 200,
        theme: 'custom',
        content:
          'Variables connected to "font-weight", "font-style" and "paragraph-indent" are not supported in Webflow. They will be converted to static values.',
      });
    },
  },
};
</script>
