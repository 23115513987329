import axios from 'axios';
import store from '@/store';

export const signFile = async (data) => {
  const URL = `get-file-signature`;
  const headers = {
    'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${store.state.token}`,
  };
  return await axios.post(URL, data, { headers });
};

export const uploadImagesToS3Bucket = async (data) => {
  const URL = `upload-images-to-bucket`;
  const headers = {
    'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${store.state.token}`,
  };
  return await axios.post(URL, data, { headers });
};

export const uploadImageToS3Bucket = async (data) => {
  const URL = `upload-image-to-bucket`;
  const headers = {
    'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${store.state.token}`,
  };
  return await axios.post(URL, data, { headers });
};
