<template>
  <div class="wf-secondary-page">
    <WfNavigationBar />
    <main>
      <section class="wf-help-page-section" v-for="section in sections" :key="section.id">
        <header>{{ section.name }}</header>
        <template v-if="section.id === 'guide'">
          <!-- <a href="https://webflow.com" target="_blank" class="button button--primary flex-center">
                        <div class="icon-wrapper flex-center">
                            <WfIcon icon="play" />
                        </div>
                        Watch in-depth tutorial
                    </a> -->
          <a
            href="https://university.webflow.com/lesson/figma-to-webflow-plugin"
            target="_blank"
            class="button button--primary flex-center"
          >
            <div class="icon-wrapper flex-center">
              <WfIcon icon="open-book" />
            </div>
            Read documentation
          </a>
        </template>
        <div class="section-content">
          <div
            class="section-content-item"
            v-for="(item, i) in section.content"
            :key="i"
            :class="{ 'align-center': section.id === 'guide' }"
          >
            <div class="icon-wrapper">
              <WfIcon :icon="item.icon" />
            </div>
            <div class="type" v-html="item.text"></div>
          </div>
        </div>
      </section>
      <section class="wf-help-page-section">
        <header>What's supported</header>
        <div class="section-content">
          <div class="type">
            The plugin works by translating Figma elements into their HTML & CSS equivalent representations in Webflow.
            Many powerful translations exist currently:
          </div>
          <ul>
            <li class="type">Auto layout, imported as Flexbox layouts</li>
            <li class="type">Typography styles</li>
            <li class="type">Border styles</li>
            <li class="type">Shadows</li>
            <li class="type">Background images and linear gradients</li>
            <li class="type">Vectors and shapes, as SVGs</li>
            <li class="type">Images</li>
            <li class="type">Opacity</li>
            <li class="type">Absolute position</li>
          </ul>
        </div>
      </section>
      <section class="wf-help-page-section">
        <header>What’s not supported (yet)</header>
        <div class="section-content">
          <div class="type">
            This plugin is a work in progress and is still in its early stages. Some functionality may be unstable. Here
            are the known limitations:
          </div>
          <ul>
            <li class="type">
              <strong>Class duplication</strong>. All Webflow classes are translated 1:1 with Figma frames’ names. Due
              to the current copy/paste limitation on the Webflow Designer, the plugin is unable to detect whether a
              class is already used in Webflow, which may lead to class duplication.
            </li>
            <li class="type">
              <strong>Custom fonts</strong> are not uploaded automatically. Upload your custom fonts in your site
              settings. <a href="https://university.webflow.com/lesson/custom-fonts" target="_blank">Learn more...</a>
            </li>
            <li class="type">
              <strong>Instances</strong> are not supported. Detach instances before copying. Pro tip: clicking on the ⚠
              icon will select all instances layers to make it easy to detach all at once
            </li>
            <li class="type">
              <strong>Figma interactions</strong> are not supported. The plugin doesn’t translate prototyping
              interactions from Figma. You can apply Webflow Interactions after pasting designs over.
            </li>
          </ul>
        </div>
        <a
          href="https://webflow.circle.so/join?invitation_token=99795801d4a6c427c3be0dd47508453032522f6c-f23ea5a4-2917-491b-8e75-37a2484e8630"
          target="_blank"
          class="button button--tertiary flex-center"
          >Leave feedback</a
        >
      </section>
    </main>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { trackEvent, PLUGIN_PAGE_VIEWED } from '@/helpers/analytics';

import WfIcon from '@/components/WfIcon.vue';
import WfNavigationBar from '@/components/ui/WfNavigationBar.vue';

export default {
  components: {
    WfIcon,
    WfNavigationBar,
  },
  props: ['structureComponents', 'layoutComponents'],
  data() {
    return {
      sections: [
        {
          id: 'guide',
          name: 'Beginner’s Guide',
          content: [
            {
              icon: 'one',
              text: 'Build a website layout using Auto Layout or add a pre-made layout. <a class="link" href="https://help.figma.com/hc/en-us/articles/5731482952599-Using-auto-layout" target="_blank">Learn Auto Layout...</a>',
            },
            { icon: 'two', text: 'Apply the HTML tags (P, H1-H6, P, A) to the appropriate layers' },
            { icon: 'three', text: 'Click <strong>Copy to Webflow</strong>' },
            { icon: 'four', text: 'Go to your Webflow project, select the element you want to paste into, and paste' },
          ],
        },
        {
          id: 'tips',
          name: 'Workflow tips',
          content: [
            {
              icon: 'info',
              text: 'Class names will be derived from layer names. Webflow will automatically apply existing classes when the same styles are detected on an imported element.',
            },
            {
              icon: 'info',
              text: 'Add a prefix to all imported classes from the settings menu if you’d like to differentiate layouts imported from Figma. ',
            },
            {
              icon: 'info',
              text: 'Pre-built layout and structure templates are built with Auto layout and are fully responsive when pasted into Webflow. ',
            },
            {
              icon: 'info',
              text: 'All grouped layers will export as SVGs. This can be handy for complex multi-layer graphics. ',
            },
            {
              icon: 'info',
              text: 'Prepend your text layers with [H1]-- to apply HTML tags more quickly. This applies to headings [H1-H6], paragraphs [P] and links [A]. ',
            },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapState(['currentSelection']),
  },
  mounted() {
    trackEvent(PLUGIN_PAGE_VIEWED, { pageName: 'Help and Feedback' });
  },
};
</script>
