import axios from 'axios';
import store from '@/store';

export const createSyncData = async (data) => {
  const authHeaders = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${store.state.token}`,
  };
  const URL = `sync/${data.site_id}`;
  return await axios.post(URL, data.payload, { headers: authHeaders });
};
