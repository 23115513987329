<template>
  <div class="wf-modal">
    <div class="content-wrapper selected-element">
      <!-- <a href="#" target="_blank" class="video-tutorial">
				<div class="play-button">
					<svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(#b)" clip-path="url(#a)"><path d="m8.333 5 23.334 15L8.333 35V5Z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" shape-rendering="crispEdges"/></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h40v40H0z"/></clipPath><filter id="b" x="3.333" y="2" width="33.333" height="40" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="2"/><feGaussianBlur stdDeviation="2"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_979_2201"/><feBlend in="SourceGraphic" in2="effect1_dropShadow_979_2201" result="shape"/></filter></defs></svg>
				</div>
			</a> -->

      <div class="video-tutorial">
        <video autoplay muted loop width="322">
          <source :src="modal.data.video_id" type="video/mp4" />
          Sorry, your browser doesn't support embedded videos.
        </video>
      </div>

      <div class="type" v-for="p in modal.data.paragraphs" :key="p.id">
        <p v-html="p"></p>
      </div>

      <button class="button button--primary" @click="setModal({ show: false, data: {} })">Done</button>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
export default {
  props: ['modal'],
  computed: {
    ...mapState(['currentSelection']),
  },
  methods: {
    ...mapMutations(['setModal']),
  },
};
</script>
