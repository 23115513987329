<template>
  <div class="wf-secondary-page">
    <WfNavigationBar />

    <main>
      <section class="wf-settings-section">
        <header>Webflow Import Settings</header>
        <div class="settings-wrapper">
          <div class="settings-item-wrapper">
            <div class="settings-item">
              <div class="wf-tooltip-wrapper">
                <div class="type type--small">Class prefix</div>
                <div class="icon-wrapper flex-center" id="cssClassPrefix">
                  <WfIcon icon="question-mark" />
                </div>
              </div>

              <DropdownMenu
                class="wf-dropdown"
                direction="right"
                @opened="isCssClassPrefixDropdownOpen = true"
                @closed="isCssClassPrefixDropdownOpen = false"
                :withDropdownCloser="true"
                :overlay="false"
                transition="none"
              >
                <template #trigger>
                  <button class="menu wf-dropdown-trigger" :class="{ 'is-active': isCssClassPrefixDropdownOpen }">
                    <div>{{ cssClassPrefix === 'null' ? 'None' : 'Custom' }}</div>
                    <div class="icon-wrapper flex-center">
                      <WfIcon icon="caret" color="#D1D4D6" />
                    </div>
                  </button>
                </template>
                <template #body>
                  <div class="dropdown-menu">
                    <div @click="updateCssClassPrefix('null')" dropdown-closer class="wf-dropdown-item">None</div>
                    <div @click="updateCssClassPrefix('figma')" dropdown-closer class="wf-dropdown-item">Custom</div>
                  </div>
                </template>
              </DropdownMenu>
            </div>
            <div v-if="cssClassPrefix !== 'null'" class="settings-item">
              <div class="type type--small">Custom class prefix</div>
              <div class="wf-input-wrapper">
                <input v-model="debounceInput" type="text" class="input-field" placeholder="prefix" />
              </div>
            </div>
          </div>
          <div class="settings-item-wrapper">
            <div class="settings-item">
              <div class="wf-tooltip-wrapper">
                <div class="type type--small">Text size unit</div>
                <div class="icon-wrapper flex-center" id="textSizeUnit">
                  <WfIcon icon="question-mark" />
                </div>
              </div>
              <DropdownMenu
                class="wf-dropdown"
                direction="right"
                @opened="isTextSizeUnitDropdownOpen = true"
                @closed="isTextSizeUnitDropdownOpen = false"
                :withDropdownCloser="true"
                :overlay="false"
                transition="none"
              >
                <template #trigger>
                  <button class="menu wf-dropdown-trigger" :class="{ 'is-active': isTextSizeUnitDropdownOpen }">
                    <div>{{ textSizeUnit === 'px' ? 'px' : 'rem' }}</div>
                    <div class="icon-wrapper flex-center">
                      <WfIcon icon="caret" color="#D1D4D6" />
                    </div>
                  </button>
                </template>
                <template #body>
                  <div class="dropdown-menu">
                    <div @click="updateTextSizeUnit('px')" dropdown-closer class="wf-dropdown-item">px</div>
                    <div @click="updateTextSizeUnit('rem')" dropdown-closer class="wf-dropdown-item">rem</div>
                  </div>
                </template>
              </DropdownMenu>
            </div>
            <div class="settings-item">
              <div class="wf-tooltip-wrapper">
                <div class="type type--small">Number variables unit</div>
                <div class="icon-wrapper flex-center" id="variableNumberTypeUnit">
                  <WfIcon icon="question-mark" />
                </div>
              </div>
              <DropdownMenu
                class="wf-dropdown"
                direction="right"
                @opened="isVariableNumberUnitsDropdownOpen = true"
                @closed="isVariableNumberUnitsDropdownOpen = false"
                :withDropdownCloser="true"
                :overlay="false"
                transition="none"
              >
                <template #trigger>
                  <button class="menu wf-dropdown-trigger" :class="{ 'is-active': isVariableNumberUnitsDropdownOpen }">
                    <div>{{ variableNumberUnit }}</div>
                    <div class="icon-wrapper flex-center">
                      <WfIcon icon="caret" color="#D1D4D6" />
                    </div>
                  </button>
                </template>
                <template #body>
                  <div class="dropdown-menu">
                    <div
                      v-for="unit in variableNumberUnits"
                      :key="unit"
                      @click="updateVariableNumberUnit(unit)"
                      dropdown-closer
                      class="wf-dropdown-item"
                    >
                      {{ unit }}
                    </div>
                  </div>
                </template>
              </DropdownMenu>
            </div>
            <div v-if="textSizeUnit === 'rem' || variableNumberUnit === 'rem'" class="settings-item">
              <div class="type type--small">Default rem value</div>
              <div class="wf-input-wrapper">
                <input v-model="remValue" type="number" class="input-field" placeholder="16" />
                <div class="small-label">px</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import { trackEvent, PLUGIN_PAGE_VIEWED } from '@/helpers/analytics';
import { mapMutations, mapState } from 'vuex';
import { dispatch } from '@/figma/ui-message-handler';
import * as _ from 'lodash';

import 'v-dropdown-menu/css';
import DropdownMenu from 'v-dropdown-menu';
import WfNavigationBar from '@/components/ui/WfNavigationBar.vue';
import WfIcon from '@/components/WfIcon.vue';

export default {
  components: {
    DropdownMenu,
    WfNavigationBar,
    WfIcon,
  },
  data() {
    return {
      remValue: '',
      debounceInput: '',

      isTextSizeUnitDropdownOpen: false,
      isCssClassPrefixDropdownOpen: false,
      isVariableNumberUnitsDropdownOpen: false,

      variableNumberUnits: ['px', 'rem'],
    };
  },
  mounted() {
    trackEvent(PLUGIN_PAGE_VIEWED, { pageName: 'Settings' });
    this.setupTooltips();
    this.remValue = this.remBaseValue;
    this.debounceInput = this.cssClassPrefix;
  },
  watch: {
    remValue: _.debounce(function (inputValue) {
      if (inputValue === '') return;
      this.handleRemValueInput(inputValue);
    }, 500),
    debounceInput: _.debounce(function (inputValue) {
      if (inputValue === '') return;
      this.handleInput(inputValue);
    }, 500),
  },
  computed: {
    ...mapState(['cssClassPrefix', 'textSizeUnit', 'variableNumberUnit', 'remBaseValue']),
  },
  methods: {
    ...mapMutations(['setCssClassPrefix', 'setTextSizeUnit', 'setVariableNumberUnit', 'setRemBaseValue']),
    handleInput(inputValue) {
      dispatch('setRootPropertyValue', { prop: 'cssClassPrefix', value: inputValue });
      this.setCssClassPrefix(inputValue);
      if (inputValue === 'null') {
        this.debounceInput = '';
      }
    },
    updateCssClassPrefix(value) {
      this.debounceInput = value;
      dispatch('setRootPropertyValue', { prop: 'cssClassPrefix', value: value });
      this.setCssClassPrefix(value);
    },
    handleRemValueInput(inputValue) {
      dispatch('setRootPropertyValue', { prop: 'remBaseValue', value: inputValue.toString() });
      this.setRemBaseValue(inputValue);
    },
    updateTextSizeUnit(value) {
      dispatch('setRootPropertyValue', { prop: 'textSizeUnit', value: value });
      this.setTextSizeUnit(value);
    },
    isOnlyNumber(evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode === 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    updateVariableNumberUnit(value) {
      dispatch('setRootPropertyValue', { prop: 'variableNumberUnit', value });
      this.setVariableNumberUnit(value);
    },

    setupTooltips() {
      tippy('#textSizeUnit', {
        maxWidth: 200,
        theme: 'custom',
        content: 'Choose if you want to import text nodes with rem or px values.',
      });
      tippy('#cssClassPrefix', {
        maxWidth: 200,
        theme: 'custom',
        content:
          'Every Figma node name will be converted into a CSS class in Webflow. Add a prefix to give you context of the class origin.',
      });
      tippy('#variableNumberTypeUnit', {
        maxWidth: 200,
        theme: 'custom',
        content: 'Choose the unit you want to sync for your "Number" variables.',
      });
    },
  },
};
</script>
