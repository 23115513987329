import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { __VERSION__ } from '@/helpers/version';

const datadogSettings = {
  clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: 'figma-to-webflow/figma-app',
  env: import.meta.env.MODE,
  version: __VERSION__,
  sessionSampleRate: 100,
};

export const initLogging = () => {
  datadogLogs.init({
    ...datadogSettings,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: ['error'],
  });

  datadogRum.init({
    ...datadogSettings,
    applicationId: import.meta.env.VITE_DATADOG_APP_ID,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    useSecureSessionCookie: true,
    useCrossSiteSessionCookie: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogRum.startSessionReplayRecording();
};

export const defaultVueErrorHandler = (error) => {
  log(error);

  //dispatch('notify', '❌ Something went wrong. We are closing the Plugin.');
  //setTimeout(() => dispatch('closePlugin'), 3000);
};

export const log = (error, context = {}) => {
  const { message } = error;
  datadogLogs.logger.error(message, context, error);
  if (import.meta.env.MODE === 'development') {
    console.log(error);
  }
};
