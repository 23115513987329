<template>
  <div id="app" :class="{ 'wf-no-scroll': modal.show }">
    <template v-if="isGlobalLoading">
      <WfIsSafariScreen v-if="isSafari" />
      <div v-else class="global-loading">
        <div class="loader">Loading...</div>
        <div class="webflow-logo">
          <WfIcon icon="webflow-logo" />
        </div>
      </div>
    </template>
    <template v-else>
      <template v-if="isLoggedIn">
        <WfHomePage
          v-if="currentPage.name === 'home'"
          :structure-components="structureComponents"
          :layout-components="layoutComponents"
        />

        <WfHelpPage v-else-if="currentPage.name === 'help'" />
        <WfSettingsPage v-else-if="currentPage.name === 'settings'" />

        <WfDashboardPage v-else-if="currentPage.name === 'dashboard'" />
        <WfAdminPage v-else-if="currentPage.name === 'admin'" :components="components" />

        <WfHandleCopyEventsRenderless />
      </template>
      <WfOnboarding v-else />

      <WfModal v-if="modal.show" :modal="modal" />
      <WfFixedBanner v-if="banner.show" :message="banner.message" />
      <WfNotification v-if="notification.show" :notification="notification" />
    </template>
    <WfHandleAuthenticationTokenRenderless />
    <WfHandleImagesRenderless />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { trackEvent, PLUGIN_LAUNCHED } from '@/helpers/analytics';
import { initLogging, log } from '@/helpers/logger';
import { dispatch, handleEvent } from '@/figma/ui-message-handler';
import { __VERSION__ } from '@/helpers/version';

import WfHomePage from '@/pages/WfHomePage.vue';
import WfHelpPage from '@/pages/WfHelpPage.vue';
import WfSettingsPage from '@/pages/WfSettingsPage.vue';
import WfDashboardPage from '@/pages/WfDashboardPage.vue';
import WfAdminPage from '@/pages/WfAdminPage.vue';

import WfHandleAuthenticationTokenRenderless from '@/components/WfHandleAuthenticationTokenRenderless.vue';
import WfHandleCopyEventsRenderless from '@/components/WfHandleCopyEventsRenderless.vue';
import WfHandleImagesRenderless from '@/components/WfHandleImagesRenderless.vue';
import WfIcon from '@/components/WfIcon.vue';
import WfOnboarding from '@/components/WfOnboarding.vue';
import WfModal from '@/components/WfModal.vue';
import WfNotification from '@/components/WfNotification.vue';

import WfIsSafariScreen from '@/components/ui/WfIsSafariScreen.vue';
import WfFixedBanner from '@/components/ui/WfFixedBanner.vue';

export default {
  components: {
    WfHomePage,
    WfHelpPage,
    WfSettingsPage,
    WfDashboardPage,
    WfAdminPage,
    WfHandleAuthenticationTokenRenderless,
    WfHandleCopyEventsRenderless,
    WfHandleImagesRenderless,
    WfIcon,
    WfOnboarding,
    WfModal,
    WfNotification,
    WfIsSafariScreen,
    WfFixedBanner,
  },
  data() {
    return {
      clipboardData: null,
      isGlobalLoading: true,
      layoutComponents: [
        { id: 'navbar-logo-left', name: 'Nav logo left', type: 'navigation' },

        { id: 'hero-heading-left', name: 'Hero heading left', description: '', type: 'hero' },
        { id: 'hero-heading-right', name: 'Hero heading right', description: '', type: 'hero' },
        { id: 'hero-stack', name: 'Hero stack', description: '', type: 'hero' },
        { id: 'hero-no-image', name: 'Hero no image', description: '', type: 'hero' },

        { id: 'form-contact', name: 'Contact form', description: '', type: 'form' },
        { id: 'form-newsletter', name: 'Newsletter form', description: '', type: 'form' },

        { id: 'team-circles', name: 'Team Circles', description: '', type: 'team' },
        { id: 'team-rectangles', name: 'Team Rectangles', description: '', type: 'team' },

        { id: 'logos-quote-block', name: 'Logos Quote Block', description: '', type: 'logos' },
        { id: 'logos-title', name: 'Logos Title', description: '', type: 'logos' },

        { id: 'gallery-overview', name: 'Gallery Overview', description: '', type: 'gallery' },
        { id: 'gallery-scroll', name: 'Gallery Scroll', description: '', type: 'gallery' },

        { id: 'features-list', name: 'Features List', description: '', type: 'features' },
        { id: 'features-metrics', name: 'Features Metrics', description: '', type: 'features' },

        { id: 'pricing-comparison', name: 'Pricing Comparison', description: '', type: 'pricing' },
        { id: 'pricing-items', name: 'Pricing Items', description: '', type: 'pricing' },

        { id: 'testimonial-column-dark', name: 'Testimonial Column Dark', description: '', type: 'testimonials' },
        { id: 'testimonial-column-light', name: 'Testimonial Column Light', description: '', type: 'testimonials' },
        { id: 'testimonial-image-left', name: 'Testimonial Image Left', description: '', type: 'testimonials' },
        { id: 'testimonial-stack', name: 'Testimonial Stack', description: '', type: 'testimonials' },

        { id: 'footer-dark', name: 'Footer', description: '', type: 'footer' },
      ],
      structureComponents: [
        { id: 'new-page', name: 'New Page', description: '', type: 'structure' },
        { id: 'section-container', name: 'Section + container', description: '', type: 'structure' },
        { id: '2-col', name: '2 col', description: '', type: 'structure' },
        { id: '3-col', name: '3 col', description: '', type: 'structure' },
        { id: '4-col', name: '4 col', description: '', type: 'structure' },
        { id: 'heading-3-col', name: 'Heading + 3 col', description: '', type: 'structure' },
        { id: '2-by-2-grid', name: '2x2 grid', description: '', type: 'structure' },
      ],
    };
  },
  mounted() {
    trackEvent(PLUGIN_LAUNCHED);

    // Getters
    dispatch('getFigmaFileName');
    dispatch('getShowNotificationsNumber');
    dispatch('getRootPropertyValue', { prop: 'textSizeUnit', value: 'px' });
    dispatch('getRootPropertyValue', { prop: 'remBaseValue', value: '16' });
    dispatch('getRootPropertyValue', { prop: 'cssClassPrefix', value: 'null' });
    dispatch('getRootPropertyValue', { prop: 'variableNumberUnit', value: 'px' });
    dispatch('getRootPropertyValue', { prop: 'isFirstTimeOnPlugin', value: 'false' });
    dispatch('getRootPropertyValue', { prop: 'hasReadDesignSystemNotification', value: 'false' });

    // Setters
    handleEvent('logError', (error) => this.logError(error));
    handleEvent('setWarningType', (data) => this.setWarning(data));
    handleEvent('remBaseValue', (value) => this.setRemBaseValue(value));
    handleEvent('textSizeUnit', (value) => this.setTextSizeUnit(value));
    handleEvent('setNotification', (data) => this.setNotification(data));
    handleEvent('setFigmaFileName', (name) => this.setFigmaFileName(name));
    handleEvent('cssClassPrefix', (value) => this.setCssClassPrefix(value));
    handleEvent('variableNumberUnit', (value) => this.setVariableNumberUnit(value));
    handleEvent('showNotificationsNumber', (value) => this.setShowNotificationsNumber(value));
    handleEvent('isFirstTimeOnPlugin', (value) => this.setIsFirstTimeOnPlugin(value === 'true'));
    handleEvent('hasReadDesignSystemNotification', (value) =>
      this.setHasReadDesignSystemNotification(value === 'true')
    );

    initLogging();

    console.log(`@figma-to-webflow/figma-app: v${__VERSION__} ✅`);
  },
  computed: {
    ...mapState(['currentPage', 'isLoggedIn', 'modal', 'notification', 'isSafari', 'banner']),
    components() {
      return [...this.layoutComponents, ...this.structureComponents];
    },
  },
  methods: {
    ...mapMutations([
      'setNotification',
      'setShowNotificationsNumber',
      'setCssClassPrefix',
      'setTextSizeUnit',
      'setWarning',
      'setIsFirstTimeOnPlugin',
      'setFigmaFileName',
      'setHasReadDesignSystemNotification',
      'setVariableNumberUnit',
      'setRemBaseValue',
    ]),
    logError(error) {
      // TODO data from sandbox might be limited/unreadable, consider improving the quality of the data (i.e. sourcemaps?)
      log(error, { service: 'figma-to-webflow/figma-sandbox' });
    },
  },
};
</script>
