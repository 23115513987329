<template>
  <div class="wf-main-tabs">
    <header>
      <nav>
        <template v-for="tab in tabs" :key="tab.id">
          <button
            :active="tab.isActive"
            class="navigation-item"
            :class="{ 'is-selected': tab.id === selectedTab }"
            @click="setSelectedTab(tab.id)"
          >
            {{ tab.name }}
          </button>
        </template>
      </nav>
      <WfOptionsMenu />
    </header>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import WfOptionsMenu from '@/components/WfOptionsMenu.vue';

export default {
  components: {
    WfOptionsMenu,
  },
  computed: {
    ...mapState(['selectedTab', 'user']),
    tabs() {
      return [
        { id: 'copy-paste', name: 'Copy/paste', isActive: true },
        { id: 'add-layout', name: 'Layouts', isActive: false },
        { id: 'design-system', name: 'Design System Sync', isActive: false },
      ];
    },
  },
  methods: {
    ...mapMutations(['setSelectedTab']),
  },
};
</script>
