<template>
  <DropdownMenu
    class="wf-options-menu-dropdown"
    direction="right"
    containerZIndex="9999"
    overlayZIndex="9998"
    :overlay="false"
    transition="none"
    :withDropdownCloser="true"
  >
    <template #trigger>
      <button class="menu flex-center">
        <WfIcon icon="settings-dark" />
      </button>
    </template>
    <template #body>
      <div class="dropdown-menu" :class="{ disabled: isLoading }">
        <div
          v-if="isAdmin"
          class="menu-item"
          @click="setCurrentPage({ name: 'admin', params: {} })"
          style="margin-bottom: 8px"
        >
          Admin
        </div>
        <div
          v-if="isAdmin"
          class="menu-item"
          @click="setCurrentPage({ name: 'dashboard', params: {} })"
          style="margin-bottom: 8px"
        >
          Dashboard
        </div>

        <a
          href="https://webflow.com"
          target="_blank"
          class="menu-item"
          dropdown-closer
          :class="{ disabled: isLoading }"
        >
          <div class="icon-wrapper flex-center">
            <WfIcon icon="webflow-icon" />
          </div>
          <div>Open Webflow Dashboard</div>
        </a>
        <div class="separator"></div>
        <div
          class="menu-item"
          v-for="style in stylesOptions"
          :key="style.id"
          dropdown-closer
          @click="handleCopyStyles(style.id)"
          :class="{ disabled: isLoading }"
        >
          <div class="icon-wrapper flex-center">
            <WfIcon :icon="style.icon" />
          </div>
          <div>{{ style.name }}</div>
        </div>
        <div class="separator"></div>
        <div
          class="menu-item"
          :class="{ disabled: isLoading }"
          @click="setCurrentPage({ name: 'settings', params: {} })"
        >
          <div class="icon-wrapper flex-center">
            <WfIcon icon="settings" />
          </div>
          <div>Plugin settings</div>
        </div>
        <div class="menu-item" :class="{ disabled: isLoading }" @click="setCurrentPage({ name: 'help', params: {} })">
          <div class="icon-wrapper flex-center">
            <WfIcon icon="question-mark-white" />
          </div>
          <div>Help & Feedback</div>
        </div>

        <WfLogOutButton :is-button="false" @updateIsLoading="updateLoading" />
      </div>
    </template>
  </DropdownMenu>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { dispatch } from '@/figma/ui-message-handler';

import DropdownMenu from 'v-dropdown-menu';
import 'v-dropdown-menu/css';
import WfIcon from '@/components/WfIcon.vue';
import WfLogOutButton from '@/components/ui/WfLogOutButton.vue';
import { trackEvent, PLUGIN_ADDITIONAL_ACTION_TAKEN } from '@/helpers/analytics';

export default {
  components: {
    DropdownMenu,
    WfIcon,
    WfLogOutButton,
  },
  data() {
    return {
      isLoading: false,
      selectedStyleId: '',
      stylesOptions: [
        { id: 'all', name: 'Copy all text & color styles', icon: 'open-book' },
        { id: 'text', name: 'Copy text styles', icon: 'text-styles' },
        { id: 'color', name: 'Copy color styles ', icon: 'color-styles' },
        // {id: "effect", name: "Copy effect styles", icon: "text-styles"},
      ],
    };
  },
  watch: {
    copiedSuccessfully(oldVal, newVal) {
      if (newVal === true) {
        this.selectedStyleId = '';
      }
    },
  },
  computed: {
    ...mapGetters(['isAdmin']),
    ...mapState(['isCopyButtonLoading', 'copiedSuccessfully']),
  },
  methods: {
    ...mapMutations(['setCurrentPage', 'setCopyButtonLoading']),

    handleCopyStyles(type) {
      this.selectedStyleId = type;
      this.setCopyButtonLoading(true);
      const payload = {
        nodes: this.currentSelection,
        type,
      };
      dispatch('handleCopyStyles', payload);

      const actionName =
        type === 'all' ? 'Copy all text and color styles' : type === 'text' ? 'Copy text styles' : 'Copy color styles';
      trackEvent(PLUGIN_ADDITIONAL_ACTION_TAKEN, { actionName });
    },
    updateLoading(value) {
      this.isLoading = value;
    },
  },
};
</script>
