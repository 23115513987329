import './bootstrap';

import { createApp } from 'vue';
import store from '@/store';
import { defaultVueErrorHandler } from '@/helpers/logger';

import './styles/app.scss';
import App from './App.vue';

const app = createApp(App);
app.config.errorHandler = defaultVueErrorHandler;
app.use(store);
app.mount('#app');
