<template>
  <section class="wf-set-html-tag-section">
    <div class="tooltip-wrapper">
      <div class="type type--small"><strong>HTML tag for current selection</strong></div>
    </div>
    <div class="wf-html-tag-selector">
      <button
        class="selection-item"
        @click="setTextTag(tag)"
        v-for="(tag, index) in availableTags"
        :key="index"
        :class="{ 'selection-item--active': wfHtmlTag === tag.id }"
      >
        {{ tag.display }}
      </button>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { dispatch } from '@/figma/ui-message-handler';
export default {
  data() {
    return {
      wfHtmlTag: 'div',
      tags: {
        text: [
          { id: 'div', display: 'Div' },
          { id: 'p', display: 'P' },
          { id: 'h1', display: 'H1' },
          { id: 'h2', display: 'H2' },
          { id: 'h3', display: 'H3' },
          { id: 'h4', display: 'H4' },
          { id: 'h5', display: 'H5' },
          { id: 'h6', display: 'H6' },
        ],
        frame: [
          { id: 'div', display: 'Div' },
          { id: 'section', display: 'Section' },
          { id: 'a', display: 'Link' },
        ],
      },
    };
  },
  mounted() {
    this.wfHtmlTag = this.getTextNodeHtmlTag(this.currentSelection);
  },
  watch: {
    currentSelection(newVal) {
      this.wfHtmlTag = this.getTextNodeHtmlTag(newVal);
    },
  },
  computed: {
    ...mapState(['currentSelection']),
    availableTags() {
      return this.currentSelection != null && this.currentSelection.type === 'TEXT' ? this.tags.text : this.tags.frame;
    },
  },
  methods: {
    setTextTag(tag) {
      this.wfHtmlTag = tag.id;
      const payload = { id: this.currentSelection.id, key: 'wfHtmlTag', value: this.wfHtmlTag };
      dispatch('updateNode', payload);

      const body = { id: this.currentSelection.id, value: `[${this.wfHtmlTag.toUpperCase()}]--` };
      dispatch('updateNodeName', body);
    },
    getTextNodeHtmlTag(node) {
      return node.wfHtmlTag == '' || node.wfHtmlTag == null || node.wfHtmlTag == 'div'
        ? this.inferHtmlTagFromName(node)
        : node.wfHtmlTag;
    },
    inferHtmlTagFromName(node) {
      if (node.name == null) return 'div';

      // Infer HTML tag from node name
      switch (node.name.toLowerCase()) {
        case 'description':
        case 'text':
        case 'paragraph':
          return 'p';
        case 'heading':
          return 'h1';
        case 'button':
          return 'a';
        default:
          return 'div';
      }
    },
  },
};
</script>
