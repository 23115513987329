const eventListeners = [];

export const dispatch = (action, data) => {
  parent.postMessage(
    { pluginMessage: { action, data }, pluginId: import.meta.env.VITE_PLUGIN_ID },
    'https://www.figma.com'
  );
};

export const handleEvent = (type, callback) => {
  eventListeners.push({ type, callback });
};

window.addEventListener('message', (event) => {
  if (event.data.pluginMessage == null) return;

  const message = event.data.pluginMessage.pluginMessage;
  if (message) {
    for (let eventListener of eventListeners) {
      if (message.action === eventListener.type) {
        eventListener.callback(message.data);
      }
    }
  }
});
