/* 
We are using Segment for analytics. This file is a helper file that contains all the analytics events that we are tracking in the app.
These events all live in figma_plugin “space” so by default everything refers to figma plugin events. 
Reference document: https://webflow.atlassian.net/wiki/x/NQCULg

Since every event has a different data structure, we need to pass the data object to the trackEvent function.
The data object is an object that contains all the data that we want to track for a specific event.
The data object is optional and can be passed as the second argument to the trackEvent function.
*/

import { eventsData } from './events';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { datadogLogs } from '@datadog/browser-logs';
export * from './events';

const analytics = AnalyticsBrowser.load({ writeKey: import.meta.env.VITE_SEGMENT_KEY });

export const trackUser = (userId) => {
  if (userId) {
    analytics.identify(userId);
    datadogLogs.setUser({ id: userId });
  } else {
    analytics.identify(null);
    datadogLogs.clearUser();
  }
};

export const trackEvent = (event, data) => {
  analytics.track(event, createEvent(event, data));
};

const createEvent = (event, data) => {
  return eventsData[event](data);
};
