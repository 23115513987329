<template>
  <nav class="wf-navigation-bar">
    <button class="back" @click="setCurrentPage({ name: 'home', params: {} })">
      <div class="flex-center">
        <WfIcon icon="back-arrow" />
      </div>
      <div class="type">Back</div>
    </button>
    <WfOptionsMenu />
  </nav>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import WfIcon from '@/components/WfIcon.vue';
import WfOptionsMenu from '@/components/WfOptionsMenu.vue';

export default {
  components: {
    WfIcon,
    WfOptionsMenu,
  },
  props: ['components'],
  data() {
    return {
      json: null,
    };
  },
  computed: {
    ...mapState(['currentPage', 'user', 'currentSelection', 'isCopyButtonLoading', 'copiedSuccessfully']),
  },
  methods: {
    ...mapMutations(['setCurrentPage']),
  },
};
</script>
