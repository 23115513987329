<template>
  <section class="wf-copy-section">
    <div v-if="userSites.length === 0" class="button-wrapper">
      <WfLogOutButton :is-button="true" />
    </div>
    <template v-else>
      <WfSites />
      <div class="main-container">
        <WfCopySectionSelection />
        <WfCopySectionCopyButton />
      </div>
    </template>
  </section>
</template>

<script>
import { mapState } from 'vuex';

import WfSites from '@/components/ui/WfSites.vue';
import WfLogOutButton from '@/components/ui/WfLogOutButton.vue';
import WfCopySectionSelection from '@/components/ui/WfCopySectionSelection.vue';
import WfCopySectionCopyButton from '@/components/ui/WfCopySectionCopyButton.vue';

export default {
  components: {
    WfLogOutButton,
    WfSites,
    WfCopySectionSelection,
    WfCopySectionCopyButton,
  },
  computed: {
    ...mapState(['userSites']),
  },
};
</script>
