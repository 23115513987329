<template>
  <div class="wf-admin-page">
    <WfHeader />
    <button
      class="button button--secondary"
      style="font-size: 11px; padding: 5px 12px"
      @click="cloneSelectedComponent()"
    >
      Clone {{ newComponentsNumber }} times the Selected Component</button
    ><br />
    <button class="button button--secondary" style="font-size: 11px; padding: 5px 12px" @click="addLocalVariables()">
      Add {{ newVariablesNumber }} Local Variables
    </button>
    <section v-if="currentSelection != null" class="selected-element">
      <div class="subtitle">Sync with Webflow</div>
      <button class="button button--secondary" style="font-size: 11px; padding: 5px 12px" @click="syncWithWebflow()">
        Sync With Webflow
      </button>
    </section>
    <section v-if="currentSelection != null" class="selected-element">
      <div class="switch">
        <input
          @change="toggleRealTimeSync()"
          id="toggleRealTimeSync"
          class="switch__toggle"
          type="checkbox"
          :checked="isRealTimeChecked"
        />
        <label class="switch__label" for="toggleRealTimeSync">Real Time Sync</label>
      </div>
    </section>
    <section class="selected-element">
      <div class="subtitle" v-text="currentSelection != null ? 'SAVE SELECTED FRAME IN DO' : 'SELECT A FRAME'"></div>
      <DropdownMenu v-if="currentSelection != null">
        <template #trigger>
          <button class="button button--secondary" style="font-size: 11px; padding: 5px 12px">Save JSON to S3</button>
        </template>
        <template #body>
          <div class="save-json-dropdown">
            <div class="component-section">
              <button
                class="button button--tertiary"
                v-for="component in components"
                :key="component.id"
                @click="saveJson(component)"
              >
                {{ component.name }}
              </button>
            </div>
          </div>
        </template>
      </DropdownMenu>
    </section>
    <div v-if="currentSelection != null" class="selected-element">
      <div class="subtitle">SELECTED NODE</div>
      <wfSelectedElementCardAdmin :element="currentSelection" />
    </div>
    <section v-if="currentSelection != null" class="selected-element">
      <div class="subtitle">GET JSON</div>
      <button class="button button--secondary" style="font-size: 11px; padding: 5px 12px" @click="getJson()">
        Get Node JSON
      </button>
      <json-viewer :value="currentSelectionJson" :expand-depth="1" expanded copyable boxed sort />
    </section>
    <section v-if="currentSelection != null" class="selected-element">
      <div class="subtitle">New Clipboard API</div>
      <button
        class="button button--secondary"
        style="font-size: 11px; padding: 5px 12px"
        @click="testNewClipboardApi()"
      >
        Copy as "text/html"
      </button>
    </section>
  </div>
</template>

<script>
import { toRaw } from 'vue';
import { log } from '@/helpers/logger';
import { dispatch, handleEvent } from '../figma/ui-message-handler';
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import * as entities from 'entities';

import JsonViewer from 'vue-json-viewer';
import DropdownMenu from 'v-dropdown-menu';
import 'v-dropdown-menu/css';
import WfHeader from '@/components/WfHeader.vue';

export default {
  props: ['components'],
  components: {
    DropdownMenu,
    JsonViewer,
    WfHeader,
  },
  data() {
    return {
      data: null,
      currentSelectionJson: {},

      isRealTimeChecked: false,
      interval: null,
      publishChannel: null,

      newVariablesNumber: 1,
      newComponentsNumber: 99,
    };
  },
  async mounted() {
    // handleEvent("copyToClipboard", (json) => this.testNewClipboardApi(json));
    handleEvent('setSyncingFromFigma', (tree) => this.syncingFromFigma(tree));

    document.addEventListener('copy', (e) => {
      if (this.isAdmin) {
        if (this.data !== null) {
          e.clipboardData.setData('text/html', this.data);
        }
      }
    });
  },
  computed: {
    ...mapGetters(['isAdmin']),
    ...mapState(['currentSelection']),
  },
  methods: {
    ...mapMutations(['setClipboardData']),
    ...mapActions(['storePreBuiltComponentJson']),
    async saveJson(component) {
      dispatch('getFullNodeJson', this.currentSelection);
      this.json = await new Promise((resolve) => handleEvent('setFullNodeJson', (value) => resolve(value)));
      const body = new FormData();
      body.append('json', this.json);
      body.append('name', component.id);
      const response = await this.storePreBuiltComponentJson(body);
      if (response) {
        dispatch('notify', '✅ Component Saved Successfully');
      }
    },
    async getJson() {
      // Adding toRaw to avoid reactivity issues. However, it might not work for more complex objects
      const currentSelection = toRaw(this.currentSelection);
      dispatch('getFullNodeJson', currentSelection);
      this.currentSelectionJson = await new Promise((resolve) =>
        handleEvent('setFullNodeJson', (value) => resolve(JSON.parse(value)))
      );
    },

    async testNewClipboardApi() {
      const fakeJson =
        '{"type":"@webflow/XscpData","payload":{"nodes":[{"_id":"322df853-9e3a-425a-adf6-8d2e22455f3c","tag":"h1","classes":["b4d4c2b9-3370-4d06-aa0e-a66bc71c0efb"],"children":["3d9dd5bb-e448-4e3d-abf8-9d53e482a69d"],"type":"Heading","data":{"text":true,"tag":"h1"}},{"_id":"3d9dd5bb-e448-4e3d-abf8-9d53e482a69d","text":true,"v":"Title Goes Here Be Awesome"}],"styles":[{"_id":"b4d4c2b9-3370-4d06-aa0e-a66bc71c0efb","_node_id":"322df853-9e3a-425a-adf6-8d2e22455f3c","type":"class","name":"text","styleLess":"color: rgba(0, 0, 0, 1); font-size: 3.5rem; line-height: 120.00000476837158%; letter-spacing: 0em; font-family: Inter, sans-serif; font-weight: 700","toDelete":false}],"assets":[],"ix1":[],"ix2":{"interactions":[],"events":[],"actionLists":[]}},"meta":{"unlinkedSymbolCount":0,"droppedLinks":0,"dynBindRemovedCount":0,"dynListBindRemovedCount":0,"paginationRemovedCount":0}}';
      const encodedJson = entities.encodeHTML(fakeJson);
      const type = 'text/html';
      const blob = new Blob([this.getHtmlString(encodedJson)], { type });
      const data = [new ClipboardItem({ [type]: blob })];
      try {
        await navigator.clipboard.write(data);
      } catch (error) {
        log(error);
      }
    },
    getHtmlString(json) {
      return `<p xmlns="http://www.w3.org/1999/xhtml"><main data-clipboard="${json}" data-clipboard-source="figma-plugin"></main></p>`;
    },

    toggleRealTimeSync() {
      this.isRealTimeChecked = !this.isRealTimeChecked;
      if (this.isRealTimeChecked) {
        this.interval = setInterval(() => {
          dispatch('syncElements');
        }, 1000);
      } else {
        this.stopSyncing();
      }
    },
    stopSyncing() {
      clearInterval(this.interval);
      this.interval = null;
    },
    async syncWithWebflow() {
      dispatch('syncElements');
    },
    async syncingFromFigma(tree) {
      this.publishChannel.publish('syncing', tree);
    },
    addLocalVariables() {
      dispatch('createNewVariables', this.newVariablesNumber);
    },
    deleteLocalVariables() {
      dispatch('deleteNewVariables', this.newVariablesNumber);
    },
    cloneSelectedComponent() {
      dispatch('cloneSelectedComponent', this.newComponentsNumber);
    },
  },
};
</script>
