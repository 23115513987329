<template>
  <div class="wf-copy-paste">
    <main>
      <WfAddLayout />
    </main>
  </div>
</template>
<script>
import { trackEvent, PLUGIN_PAGE_VIEWED } from '@/helpers/analytics';
import WfAddLayout from '@/components/new-layout/WfAddLayout.vue';
export default {
  components: {
    WfAddLayout,
  },
  mounted() {
    trackEvent(PLUGIN_PAGE_VIEWED, { pageName: 'Layouts' });
  },
};
</script>
