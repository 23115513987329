<template>
  <div class="wf-copy-paste">
    <WfCopySection />
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
import { trackEvent, PLUGIN_PAGE_VIEWED } from '@/helpers/analytics';
import WfCopySection from '@/components/ui/WfCopySection.vue';

onMounted(() => {
  trackEvent(PLUGIN_PAGE_VIEWED, { pageName: 'Copy paste' });
});
</script>
