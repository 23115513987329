import axios from 'axios';
import store from '@/store';
import headers from './headers';

export const generateKeys = async (data) => {
  const URL = `generate-keys`;
  return await axios.post(URL, data, { headers });
};

export const getAuthenticatedUser = async (data) => {
  const URL = `get-authenticated-user`;
  return await axios.post(URL, data, { headers });
};

export const getUserData = async (data) => {
  const authHeaders = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${data.writeKey}`,
  };
  const URL = `get-user-data`;
  return await axios.post(URL, {}, { headers: authHeaders });
};

export const logUserOut = async () => {
  const authHeaders = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${store.state.token}`,
  };
  const URL = `log-user-out`;
  return await axios.post(URL, {}, { headers: authHeaders });
};

export const deleteKeys = async (data) => {
  const URL = `delete-keys`;
  return await axios.post(URL, data, { headers });
};
