// Create a javascript function that given a string it slices it into chunks of 16384 bytes and returns an array of chunks with the original string and the index of the chunk
// Calculate the size of the string and divide it by 16384 to get the number of chunks
// Example: "Hello world" => ["Hello", 0], [" world", 1]
export const sliceString = (str) => {
  const chunks = [];
  const chunkSize = 16384;
  const stringSize = new Blob([str]).size;
  const chunksCount = Math.ceil(stringSize / chunkSize);
  for (let i = 0, o = 0; i < chunksCount; ++i, o += chunkSize) {
    chunks.push([str.substr(o, chunkSize), i]);
  }
  return chunks;
};

export const logTime = (startTime, length, description) => {
  const endTime = performance.now();

  // Calculate the elapsed time in seconds
  const elapsedTimeInSeconds = (endTime - startTime) / 1000;
  // Round the elapsed time to two decimal places
  const roundedElapsedTimeInSeconds = Math.round(elapsedTimeInSeconds * 100) / 100;

  // Log the elapsed time (in seconds)
  console.log(description + ' Took ' + roundedElapsedTimeInSeconds + ' seconds for ' + length + ' components');
};

export const getSyncDuration = (startTime) => {
  const endTime = performance.now();
  const elapsedTimeInSeconds = (endTime - startTime) / 1000;
  return Math.round(elapsedTimeInSeconds * 100) / 100;
};
