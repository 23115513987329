<template>
  <div class="wf-warning" @click="handleWarningClick()">
    <WfIcon icon="warning" :color="isBlocking ? '#FFC700' : '#303030'" />
    <div class="wf-tooltip" v-html="message"></div>
  </div>
</template>

<script>
import { dispatch } from '@/figma/ui-message-handler';
import { mapState } from 'vuex';
import { toRaw } from 'vue';

import WfIcon from '@/components/WfIcon.vue';

export default {
  components: {
    WfIcon,
  },
  props: {
    isBlocking: {
      type: Boolean,
    },
    message: {
      type: String,
    },
    isComponentsSync: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(['warning']),
  },
  methods: {
    handleWarningClick() {
      if (this.isComponentsSync) return;
      const nodes = toRaw(this.warning.data.nodes);
      dispatch('selectNodes', nodes);
    },
  },
};
</script>
