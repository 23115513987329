<template>
  <div class="wf-variables-table">
    <div class="variables-header">
      <div class="table-cell">
        <div
          class="wf-checkbox"
          @click="handleBulkSelection()"
          :class="{
            unchecked: $parent.selectedVariables.length === 0,
            indeterminant: isIndeterminant,
          }"
        >
          <WfIcon :icon="isIndeterminant ? 'hyphen' : 'checkmark'" color="#FFF" />
        </div>
        <template v-if="$parent.collections.length > 1">
          <DropdownMenu
            class="wf-selected-site-dropdown"
            direction="left"
            :withDropdownCloser="true"
            :overlay="false"
            transition="none"
            @opened="isCollectionsOpen = true"
            @closed="isCollectionsOpen = false"
          >
            <template #trigger>
              <button class="menu is-trigger" :class="{ 'is-open': isCollectionsOpen }">
                <div>{{ selectedCollection.name }}</div>
                <div class="icon-wrapper flex-center">
                  <WfIcon icon="caret" color="#B2B2B2" />
                </div>
              </button>
            </template>
            <template #body>
              <div class="dropdown-menu">
                <div class="menu-item" v-for="collection in $parent.collections" :key="collection.id" dropdown-closer>
                  <div class="item-icon-wrapper flex-center" :class="{ show: collection.id === selectedCollection.id }">
                    <WfIcon icon="checkmark" color="#FFF" />
                  </div>
                  <input
                    type="radio"
                    :id="collection.id"
                    name="collections"
                    :value="collection.name"
                    @change="selectCollection(collection)"
                    :checked="collection.id === selectedCollection.id"
                  />
                  <label :for="collection.id">{{ collection.name }}</label>
                </div>
              </div>
            </template>
          </DropdownMenu>
        </template>
        <template v-else>{{ selectedCollection.name }}</template>
      </div>
      <div class="table-cell" v-if="selectedCollectionModes.length > 1">
        <DropdownMenu
          class="wf-selected-site-dropdown"
          direction="right"
          :withDropdownCloser="true"
          :overlay="false"
          transition="none"
          @opened="isModesOpen = true"
          @closed="isModesOpen = false"
        >
          <template #trigger>
            <button class="menu is-trigger" :class="{ 'is-open': isModesOpen }">
              <div>{{ selectedMode.name }}</div>
              <div class="icon-wrapper flex-center">
                <WfIcon icon="caret" color="#B2B2B2" />
              </div>
            </button>
          </template>
          <template #body>
            <div class="dropdown-menu">
              <div class="menu-item" v-for="mode in selectedCollectionModes" :key="mode.id" dropdown-closer>
                <div class="item-icon-wrapper flex-center" :class="{ show: mode.id === selectedMode.id }">
                  <WfIcon icon="checkmark" color="#FFF" />
                </div>
                <input
                  type="radio"
                  :id="mode.id"
                  name="modes"
                  :value="mode.name"
                  @change="selectMode(mode)"
                  :checked="mode.id === selectedMode.id"
                />
                <label :for="mode.id">{{ mode.name }}</label>
              </div>
            </div>
          </template>
        </DropdownMenu>
      </div>
      <div class="table-cell" v-else>{{ selectedMode.name }}</div>
    </div>

    <template v-if="selectedModeVariables.length > 0">
      <div class="variables-row" v-for="variable in selectedModeVariables" :key="variable.id">
        <div class="variable-name table-cell" @click="setVariable(variable)">
          <div class="wf-checkbox" :class="{ unchecked: !variable.isSelected }">
            <WfIcon v-if="variable.isSelected" icon="checkmark" color="#FFF" />
          </div>
          <WfIcon v-if="variable.type === 'COLOR'" icon="color-variable" />
          <WfIcon v-else-if="variable.type === 'STRING'" icon="typography-icon" />
          <WfIcon v-else icon="hashtag" />
          <div class="type type--small text-ellipsis">{{ variable.name }}</div>
        </div>
        <div class="variable-value table-cell">
          <div
            v-if="variable.type === 'COLOR'"
            class="wf-color-swatch-wrapper"
            style="background-image: url('/transparency.png')"
          >
            <div class="color-swatch" :style="{ background: getBackgroundColor(variable) }"></div>
          </div>
          <div class="type type--small text-ellipsis">
            <template v-if="variable.type === 'COLOR'">
              {{ `#${getHexFromString(variable.value)}` }}
            </template>
            <template v-else-if="variable.type === 'LENGTH'">
              {{ variable.value.value }}
              <span id="numberValue" @click="setCurrentPage({ name: 'settings', params: {} })" class="variable-unit"
                >{{ variable.value.unit }}
              </span>
            </template>
            <template v-else-if="variable.type === 'STRING'">
              <span class="font-family-name">{{ variable.value }}</span></template
            >
          </div>
        </div>
      </div>
    </template>
    <div v-else class="wf-no-variables">
      <div class="wf-select-frame">
        <WfIcon icon="no-variables-selected" />
        <div class="type type--small">
          <strong>No variables</strong><br /><br />
          You don't have variables in this <strong>collection</strong>. Go ahead and create some to sync them to
          Webflow.
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import tinycolor from 'tinycolor2';
import { mapMutations } from 'vuex';

import DropdownMenu from 'v-dropdown-menu';
import 'v-dropdown-menu/css';
import WfIcon from '@/components/WfIcon.vue';

export default {
  components: {
    DropdownMenu,
    WfIcon,
  },
  data() {
    return {
      isModesOpen: false,
      isCollectionsOpen: false,
    };
  },
  mounted() {
    this.setupTooltips();
    this.emitSelectedVariables();
  },
  computed: {
    selectedCollection() {
      return this.$parent.collections.find((collection) => collection.isSelected);
    },
    selectedMode() {
      return this.$parent.modes.find((mode) => mode.isSelected);
    },
    selectedCollectionModes() {
      return this.$parent.modes.filter((mode) => mode.collectionId === this.selectedCollection.id);
    },
    selectedModeVariables() {
      return this.$parent.variables.filter((variable) => variable.modeId === this.selectedMode.id);
    },
    areAllVariablesSelectedFromSelectedMode() {
      return this.selectedModeVariables.length === this.$parent.selectedVariables.length;
    },
    isIndeterminant() {
      return !this.areAllVariablesSelectedFromSelectedMode && this.$parent.selectedVariables.length > 0;
    },
  },
  methods: {
    ...mapMutations(['setCurrentPage']),
    setVariable(variable) {
      const selectedVariable = this.selectedModeVariables.find((v) => v.id === variable.id);
      selectedVariable.isSelected = !selectedVariable.isSelected;
      this.emitSelectedVariables();
    },
    emitSelectedVariables() {
      this.$emit('setSelectedVariables', this.getSelectedVariables());
    },
    getSelectedVariables() {
      return this.selectedModeVariables.filter((variable) => variable.isSelected);
    },
    selectCollection(collection) {
      this.$parent.collections.forEach((c) => (c.isSelected = c.id === collection.id));
      this.$parent.modes.forEach((m) => (m.isSelected = m.id === this.selectedCollection.defaultModeId));
      this.emitSelectedVariables();
    },
    selectMode(mode) {
      this.$parent.modes.forEach((m) => (m.isSelected = m.id === mode.id));
      this.emitSelectedVariables();
    },
    handleBulkSelection() {
      if (this.areAllVariablesSelectedFromSelectedMode) {
        this.selectedModeVariables.forEach((variable) => (variable.isSelected = false));
      } else {
        this.selectedModeVariables.forEach((variable) => (variable.isSelected = true));
      }
      this.emitSelectedVariables();
    },
    getBackgroundColor(variable) {
      if (variable.type !== 'COLOR') return;
      const value = tinycolor(variable.value).toRgb();
      const rgba = {
        r: value.r,
        g: value.g,
        b: value.b,
        a: value.a,
      };
      return `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`;
    },
    getHexFromString(string) {
      return tinycolor(string).toHex();
    },
    setupTooltips() {
      tippy('#numberValue', {
        maxWidth: 200,
        theme: 'custom',
        content:
          'Click here to globally change the unit value for your Number variables. Use the "Number variables unit" setting.',
      });
    },
  },
};
</script>
