<template>
  <div class="wf-add-layout">
    <div class="wf-sticky-header" :class="{ 'is-scrolling': isScrolling }">
      <DropdownMenu
        class="wf-selected-site-dropdown"
        direction="left"
        :withDropdownCloser="true"
        :overlay="false"
        transition="none"
        @opened="isOpen = true"
        @closed="isOpen = false"
        :isOpen="isOpen"
      >
        <template #trigger>
          <button class="menu flex-center is-trigger" :class="{ 'is-open': isOpen }">
            <div class="site-name-wrapper">
              <div class="site-name">
                {{ selectedName }}
              </div>
              <div class="flex-center dropdown-tooltip" id="tooltip">
                <WfIcon icon="question-mark" />
              </div>
            </div>
            <div class="icon-wrapper flex-center">
              <WfIcon icon="caret" color="#B2B2B2" />
            </div>
          </button>
        </template>
        <template #body>
          <div class="dropdown-menu">
            <div
              class="menu-item"
              v-for="category in categories"
              :key="category.id"
              dropdown-closer
              @click="updateSelectedType(category)"
            >
              <div class="item-icon-wrapper flex-center" :class="{ show: category.id === selectedTypeId }">
                <WfIcon icon="checkmark" color="#FFF" />
              </div>
              <input
                type="radio"
                :id="category.id"
                name="category"
                :value="category.name"
                @change="updateSelectedType(category)"
                :checked="category.id === selectedTypeId"
              />
              <label :for="category.id">{{ category.name }}</label>
            </div>
            <div class="separator"></div>
            <div class="menu-item" v-for="type in types" :key="type.id" dropdown-closer>
              <div class="item-icon-wrapper flex-center" :class="{ show: type.id === selectedTypeId }">
                <WfIcon icon="checkmark" color="#FFF" />
              </div>
              <input
                type="radio"
                :id="type.id"
                name="types"
                :value="type.name"
                @change="updateSelectedType(type)"
                :checked="type.id === selectedTypeId"
              />
              <label :for="type.id">{{ type.name }}</label>
            </div>
          </div>
        </template>
      </DropdownMenu>
    </div>
    <div class="wf-components-section" :class="{ 'is-loading-component': isLoadingComponent }">
      <WfComponentItem
        v-for="component in selectedComponents"
        :key="component.id"
        :component="component"
        @isLoading="handleLoading"
      />
    </div>
  </div>
</template>
<script>
import tippy from 'tippy.js';
import { mapState } from 'vuex';
import 'tippy.js/dist/tippy.css';

import DropdownMenu from 'v-dropdown-menu';
import 'v-dropdown-menu/css';
import WfIcon from '@/components/WfIcon.vue';
import WfComponentItem from '@/components/WfComponentItem.vue';

export default {
  components: {
    DropdownMenu,
    WfIcon,
    WfComponentItem,
  },
  data() {
    return {
      isLoadingComponent: false,

      isOpen: false,
      selectedTypeId: 'all',
      categories: [
        { id: 'all', name: 'All items' },
        { id: 'layout', name: 'Layouts' },
        { id: 'structure', name: 'Structure' },
      ],
    };
  },
  mounted() {
    this.setupTooltips();
  },
  computed: {
    ...mapState(['preBuiltComponents', 'isScrolling']),
    selectedName() {
      const category = this.categories.find((c) => c.id === this.selectedTypeId);
      return category ? category.name : this.types.find((t) => t.id === this.selectedTypeId).name;
    },
    selectedComponents() {
      switch (this.selectedTypeId) {
        case 'all':
          return this.preBuiltComponents;
        case 'layout':
        case 'structure':
          return this.preBuiltComponents.filter((c) => c.category === this.selectedTypeId);

        default:
          return this.preBuiltComponents.filter((c) => c.type === this.selectedTypeId);
      }
    },
    types() {
      return this.preBuiltComponents
        .map((c) => {
          return { id: c.type, name: c.type.charAt(0).toUpperCase() + c.type.slice(1) };
        })
        .filter((c, index, self) => self.findIndex((t) => t.id === c.id) === index)
        .filter((c) => c.id !== 'structure');
    },
  },
  methods: {
    handleLoading(value) {
      this.isLoadingComponent = value;
    },
    updateSelectedType(type) {
      this.selectedTypeId = type.id;
      this.isOpen = false;
    },
    setupTooltips() {
      tippy('#tooltip', {
        maxWidth: 270,
        theme: 'custom',
        content:
          'Layouts and Structure templates are built with auto layout and are fully responsive when copied to Webflow',
      });
    },
  },
};
</script>
