<template>
  <div class="wf-select-site">
    <DropdownMenu
      class="wf-selected-site-dropdown"
      direction="left"
      :withDropdownCloser="true"
      :overlay="false"
      transition="none"
      @opened="isOpen = true"
      @closed="isOpen = false"
      :isOpen="isOpen"
    >
      <template #trigger>
        <button class="menu is-trigger" :class="{ 'is-open': isOpen }">
          <div class="start">
            <WfIcon icon="site-icon" />
            <div class="site-name">{{ selectedSiteName }}</div>
          </div>
          <div class="icon-wrapper flex-center">
            <WfIcon icon="caret" color="#B2B2B2" />
          </div>
        </button>
      </template>
      <template #body>
        <div class="dropdown-menu">
          <div class="input-wrapper">
            <div class="icon-wrapper flex-center">
              <WfIcon icon="magnifier" />
            </div>
            <input v-model="sitesQuery" type="text" ref="queryStringInput" placeholder="Type to search your sites" />
            <button
              class="button icon-wrapper flex-center"
              :class="{ hide: sitesQuery === '' }"
              @click="resetQueryString()"
            >
              <WfIcon icon="search-times" />
            </button>
          </div>
          <div class="separator"></div>
          <div v-if="filteredUserSites.length < 1 && sitesQuery !== ''" class="empty-state flex-center">
            <label>No sites found</label>
          </div>
          <div class="menu-item" v-for="site in filteredUserSites" :key="site.id" dropdown-closer>
            <div class="item-icon-wrapper flex-center" :class="{ show: site.id === selectedSiteId }">
              <WfIcon icon="checkmark" color="#FFF" />
            </div>
            <input
              type="radio"
              :id="site.id"
              name="sites"
              :value="site.displayName"
              @change="updateSelectedSite(site)"
              :checked="site.id === selectedSiteId"
            />
            <label :for="site.id">{{ site.displayName }}</label>
          </div>
          <div class="separator"></div>
          <div class="disclaimer">
            To add or remove sites, log out of the plugin and edit sites in the authentication window.
          </div>
        </div>
      </template>
    </DropdownMenu>
  </div>
</template>

<script>
import Fuse from 'fuse.js';
import { mapMutations, mapState } from 'vuex';

import DropdownMenu from 'v-dropdown-menu';
import 'v-dropdown-menu/css';
import WfIcon from '@/components/WfIcon.vue';

export default {
  components: {
    DropdownMenu,
    WfIcon,
  },
  data() {
    return {
      sitesQuery: '',
      isOpen: false,
    };
  },
  computed: {
    ...mapState(['userInfo', 'userSites', 'selectedSiteId']),

    authorizedSites() {
      return this.userSites.some((site) => site.id === this.selectedSiteId);
    },
    selectedSiteName() {
      return this.userSites.find((site) => site.id === this.selectedSiteId)?.displayName || 'None selected';
    },
    filteredUserSites() {
      if (!this.sitesQuery || this.sitesQuery === '') {
        return this.userSites;
      }

      let fuse = new Fuse(this.userSites, { threshold: 0.25, keys: ['displayName'] });
      let fuseSearch = fuse.search(this.sitesQuery);
      let sanitizeFuseSearch = [];
      fuseSearch.forEach((item) => sanitizeFuseSearch.push(item.item));
      return sanitizeFuseSearch;
    },
  },
  methods: {
    ...mapMutations(['setSelectedSiteId']),
    inBoth(list1, list2, isUnion = false) {
      return list1.filter(
        (
          (set) => (a) =>
            isUnion === set.has(a.id)
        )(new Set(list2.map((b) => b)))
      );
    },
    resetQueryString() {
      this.$refs.queryStringInput.focus();
      this.sitesQuery = '';
    },
    closeDropdown() {
      this.sitesQuery = '';
      this.isOpen = false;
    },
    updateSelectedSite(site) {
      this.$store.dispatch('updateSelectedSite', { site });
      this.closeDropdown();
    },
  },
};
</script>
