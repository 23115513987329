<!-- eslint-disable vue/valid-template-root -->
<template></template>

<script>
import { isSafari } from '@/helpers/utils';
import { mapMutations, mapActions } from 'vuex';
import { trackEvent, trackUser, PLUGIN_SIGNIN } from '@/helpers/analytics';
import { log } from '@/helpers/logger';
import { dispatch, handleEvent } from '../figma/ui-message-handler';
export default {
  data() {
    return {
      intervalId: null,
    };
  },
  mounted() {
    dispatch('getClientStorage', 'token');
    handleEvent('clientStorageResponse', (token) => this.handleAuthentication(token));
  },
  methods: {
    ...mapActions(['getUser', 'getInfo', 'generateKeys', 'getAuthenticatedUser', 'getUserData']),
    ...mapMutations(['setToken', 'setIsLoggedIn', 'setUser', 'setUserInfo', 'setUserSites', 'setIsSafari']),

    async handleAuthentication(token) {
      if (isSafari) {
        this.setIsSafari(true);
        return;
      }
      if (token === null) {
        this.setIsLoggedIn(false);
      } else {
        // Get user info and log in.
        try {
          const userData = await this.setUserData(token);
          if (userData != null) {
            this.setToken(token);
            this.setIsLoggedIn(true);
          }
        } catch (error) {
          log(error);
          this.logUserOut();
          return null;
        }
      }
      this.$parent.isGlobalLoading = false;
    },
    async setUserData(token) {
      try {
        const user = await this.getUserData({ writeKey: token });
        if (user == null || user == 'error') {
          this.logUserOut();
          return null;
        }
        const userData = user.user.user;
        const userInfo = user.user.userInfo;
        const userSites = user.user.userSites;

        if (userData == null || userInfo === false) {
          this.logUserOut();
          return null;
        }
        this.setUser(userData);
        this.setUserInfo(userInfo);
        this.setUserSites(userSites);
        this.identifyUserForSegment(userData);
        return userData;
      } catch (error) {
        log(error);
        this.logUserOut();
        return null;
      }
    },

    logUserOut() {
      dispatch('setClientStorage', { name: 'token', data: null });

      this.setUser(null);
      this.setToken(null);
      this.setUserInfo(null);
      this.setIsLoggedIn(false);

      this.$parent.isGlobalLoading = false;
    },
    identifyUserForSegment(user) {
      trackUser(user.id);
      trackEvent(PLUGIN_SIGNIN);
    },
  },
};
</script>
