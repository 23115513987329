<template>
  <div class="wf-design-system">
    <WfSiteAndTabsSelection />
    <main>
      <template v-if="designSystemSyncSelectedTab === 'components'">
        <WfComponentsToSync />
        <WfSyncComponentsButton />
      </template>
      <WfVariablesToSync v-else />
    </main>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { handleEvent } from '@/figma/ui-message-handler';

import WfSiteAndTabsSelection from '@/components/new-layout/WfSiteAndTabsSelection.vue';
import WfComponentsToSync from '@/components/new-layout/WfComponentsToSync.vue';
import WfSyncComponentsButton from '@/components/new-layout/WfSyncComponentsButton.vue';
import WfVariablesToSync from '@/components/new-layout/WfVariablesToSync.vue';

export default {
  components: {
    WfSiteAndTabsSelection,
    WfComponentsToSync,
    WfSyncComponentsButton,
    WfVariablesToSync,
  },
  data() {
    return {
      hasInfo: false,
      isBlocking: false,
      hasOneOrMoreFrameSelected: false,

      selectedVariables: [],
      selectedComponents: [],
    };
  },
  mounted() {
    handleEvent('selectionChanged', () => (this.hasInfo = false));
    handleEvent('setHasOneOrMoreFrameSelected', (value) => this.setHasOneOrMoreFrameSelected(value));
    handleEvent('setSelectedComponents', (payload) => this.setSelectedComponentsAndVariables(payload));
  },
  computed: {
    ...mapState(['designSystemSyncSelectedTab']),
  },
  methods: {
    setSelectedComponentsAndVariables(payload) {
      this.selectedComponents = payload.components;
      this.selectedVariables = payload.variables;
    },
    setHasOneOrMoreFrameSelected(value) {
      this.hasOneOrMoreFrameSelected = value;
      if (!value) {
        this.hasInfo = false;
      }
    },
  },
};
</script>
