<template>
  <div class="wf-admin-page">
    <WfHeader />
    This is the dashboard-
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import WfHeader from '@/components/WfHeader.vue';

export default {
  components: {
    WfHeader,
  },
  data() {
    return {
      currentSelectionJson: {},
    };
  },
  computed: {
    ...mapState(['currentSelection']),
  },
  methods: {
    ...mapActions(['storePreBuiltComponentJson']),
  },
};
</script>
