<template>
  <div class="component-item" :class="{ 'is-loading': isLoadingComponent }" @click="handleComponentClick()">
    <div class="component-item-image">
      <WfThumbnail :thumbnail="component.id" />
    </div>
    <div class="component-item-name">{{ component.name }}</div>
    <div class="icon-wrapper">
      <div v-if="isLoadingComponent && selectedComponent.id === component.id" class="icon icon--spinner">
        <svg fill="none" width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
          <path
            clip-rule="evenodd"
            d="m15.1645 8.52993c.0489.27178-.1318.53173-.4036.58061-1.3104.23568-2.5265.84022-3.5054 1.74266-.979.9025-1.68029 2.0654-2.0216 3.3524s-.3085 2.6446.09459 3.9136 1.15971 2.3967 2.18121 3.2508c1.0214.8541 2.2652 1.3992 3.5855 1.5713s2.6623-.0359 3.8685-.5997c1.2063-.5637 2.2267-1.4598 2.9416-2.583.715-1.1233 1.0947-2.4271 1.0947-3.7586 0-.2761.2239-.5.5-.5s.5.2239.5.5c0 1.5217-.434 3.0118-1.251 4.2955-.8171 1.2837-1.9833 2.3078-3.3619 2.9521-1.3785.6442-2.9122.882-4.4211.6853-1.509-.1967-2.9305-.8196-4.0978-1.7958-1.16736-.9761-2.0321-2.2649-2.49278-3.7152-.46068-1.4502-.49818-3.0018-.10811-4.4726.39007-1.4709 1.19154-2.8 2.31039-3.8313 1.1188-1.03141 2.5086-1.72232 4.0062-1.99167.2718-.04888.5317.13181.5806.4036z"
            fill="#000"
            fill-rule="evenodd"
          />
        </svg>
      </div>
    </div>
    <div class="wf-tooltip">Click to add to canvas</div>
  </div>
</template>

<script>
import { dispatch, handleEvent } from '@/figma/ui-message-handler';
import { log } from '@/helpers/logger';
import WfThumbnail from '@/components/ui/WfThumbnail.vue';
import { trackEvent, PREBUILT_COMPONENT_ADDED } from '@/helpers/analytics';

export default {
  components: {
    WfThumbnail,
  },
  props: ['component'],
  data() {
    return {
      selectedComponent: null,
      isLoadingComponent: false,
    };
  },
  mounted() {
    handleEvent('componentWasAddedSuccessfully', () => this.handleComponentWasAddedSuccessfully());
  },
  methods: {
    async handleComponentClick() {
      this.selectedComponent = this.component;
      this.isLoadingComponent = true;
      this.$emit('isLoading', true);

      const url = `https://figma-to-webflow.s3.us-west-1.amazonaws.com/webflow/pre-built-components/${this.component.id}.json?nocache=${new Date().getTime()}`;
      fetch(url)
        .then((response) => {
          if (!response.ok) throw new Error('HTTP error ' + response.status);
          return response.json();
        })
        .then((data) => {
          dispatch('createComponent', data);
          trackEvent(PREBUILT_COMPONENT_ADDED, { componentType: this.component.id });
          return;
        })
        .catch((error) => {
          log(error);
          this.selectedComponent = null;
          this.isLoadingComponent = false;
        });
    },
    handleComponentWasAddedSuccessfully() {
      // [TODO] It looks like this methods is being called multiple times
      // after the prebuilt component is added. Need to investigate why.
      this.selectedComponent = null;
      this.isLoadingComponent = false;
      this.$emit('isLoading', false);
    },
  },
};
</script>
