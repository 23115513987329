<template>
  <div class="wf-onboarding">
    <WfOnboardingScreen
      v-if="step === 0"
      image="/error.png"
      :title="errorTitle"
      :description="errorMessage"
      :has-prev="false"
      :has-next="false"
      :step="step"
      :has-get-started="false"
      :has-start="false"
      :has-error="true"
      @next-clicked="step++"
      @previous-clicked="step--"
      @try-again="step = 1"
    />
    <WfOnboardingScreen
      v-if="step === 1"
      image="/auto-layout.png"
      title="Auto layout --> live website"
      description="Copy an Auto layout frame in Figma and paste it in Webflow as a responsive flexbox structure. Adjust HTML tags from within Figma to transition more quickly to a live website."
      :has-prev="false"
      :has-next="true"
      :step="step"
      :has-get-started="false"
      :has-start="false"
      :has-error="false"
      @next-clicked="step++"
      @previous-clicked="step--"
    />
    <WfOnboardingScreen
      v-if="step === 2"
      image="/templates.png"
      title="Templates = super powers"
      description="Get started more quickly with blank structure templates or pre-built layouts. These elements are automatically responsive when pasted into Webflow, including navbars. "
      :has-prev="true"
      :has-next="true"
      :step="step"
      :has-get-started="false"
      :has-start="false"
      :has-error="false"
      @next-clicked="step++"
      @previous-clicked="step--"
    />
    <WfOnboardingScreen
      v-if="step === 3"
      image="/connect.png"
      title="Connect to your Webflow account"
      description="Choose the Webflow site(s) or workspace(s) you want to import your Figma designs into."
      :has-prev="true"
      :has-next="false"
      :step="step"
      :has-get-started="true"
      :has-start="false"
      :has-error="false"
      :is-loading="isLoading"
      @next-clicked="step++"
      @previous-clicked="step--"
      @get-started="connectoToWebflowAccount()"
    />
    <WfOnboardingScreen
      v-if="step === 4"
      image="/success.png"
      title="Webflow account successfully linked"
      description="Webflow — From Figma to Webflow — now has permission to move layouts directly from Figma. Now that we’ve got the logistics out of the way, let’s get moving those designs over."
      :has-prev="false"
      :has-next="false"
      :step="step"
      :has-get-started="false"
      :has-start="true"
      :has-error="false"
      :is-loading="isLoading"
      @next-clicked="step++"
      @previous-clicked="step--"
      @start="getUserInfo()"
    />
  </div>
</template>

<script>
import {
  trackEvent,
  trackUser,
  PLUGIN_SIGNIN,
  OAUTH_INITIATED,
  OAUTH_COMPLETED,
  ACCEPTED_TERMS,
} from '@/helpers/analytics';
import { dispatch } from '../figma/ui-message-handler';
import { mapState, mapMutations, mapActions } from 'vuex';

import WfOnboardingScreen from '@/components/ui/WfOnboardingScreen.vue';

export default {
  components: {
    WfOnboardingScreen,
  },
  data() {
    return {
      key: '',
      code: '',

      step: 1,
      readKey: '',
      pollTries: 10,
      isLoading: false,

      hasOptIn: true,

      errorTitle: 'Oops, did you authenticate with Webflow?',
      errorMessage:
        "It looks like something went wrong during authentication. Please try again. Don't forget to authenticate with Webflow.",
    };
  },
  computed: {
    ...mapState(['user', 'userInfo', 'authorizationCode']),
  },
  methods: {
    ...mapActions(['generateKeys', 'getAuthenticatedUser', 'getUserData', 'deleteKeys']),
    ...mapMutations([
      'setIsLoggedIn',
      'setToken',
      'setUser',
      'setUserInfo',
      'setUserSites',
      'setShowNotificationsNumber',
      'setWriteKey',
    ]),

    async connectoToWebflowAccount() {
      this.isLoading = true;
      const keys = await this.generateKeys();
      if (keys === null) return;
      this.setWriteKey(keys.write);
      this.readKey = keys.read;
      this.getWebflowCode(keys.read);
      trackEvent(OAUTH_INITIATED);
    },
    async getWebflowCode(read) {
      const clientId = import.meta.env.VITE_WEBFLOW_CLIENT_ID;
      const endpoint = import.meta.env.VITE_WF_API_URL;
      const scope = ['assets:write', 'authorized_user:read', 'sites:read'].join(' ');
      const url = `${endpoint}/oauth/authorize?client_id=${clientId}&response_type=code&state=${read}&scope=${scope}`;
      window.open(url, '_blank');

      // Change to Let's start screen after 3 seconds
      setTimeout(() => {
        this.step = 4;
        this.isLoading = false;
      }, 3000);
    },
    async getUserInfo() {
      this.isLoading = true;
      const payload = new FormData();
      payload.append('readKey', this.readKey);
      payload.append('hasOptIn', this.hasOptIn);

      const user = await this.getAuthenticatedUser(payload);
      if (user.user == null) {
        this.showErrorScreen(this.readKey);
        return;
      }
      if (user?.user != null) {
        if (user.user == 'error') {
          this.showErrorScreen(this.readKey);
          return;
        }

        const userData = user.user;
        if (userData == null) {
          this.showErrorScreen(this.readKey);
          return;
        }

        this.setUser(userData);
        const userInfo = user.userInfo;
        const userSites = user.userSites;
        // Check if the user has authorized sites
        // if not, show error screen and return.
        if (userSites == null || userSites.length < 1) {
          this.showErrorScreen(this.readKey, false);
          return;
        }

        this.setUserInfo(userInfo);
        this.setUserSites(userSites);
        this.identifyUserForSegment(userData);
        const write = user.writeKey;
        const tokenObj = { name: 'token', data: write };
        dispatch('setClientStorage', tokenObj);
        this.setToken(write);

        this.isLoading = false;
        this.setIsLoggedIn(true);
        this.$parent.isGlobalLoading = false;

        trackEvent(OAUTH_COMPLETED);

        return;
      }
    },
    identifyUserForSegment(user) {
      trackUser(user.id);
      trackEvent(PLUGIN_SIGNIN);
      trackEvent(ACCEPTED_TERMS);
    },
    showErrorScreen(read, hasAuthorizedSites = true) {
      if (!hasAuthorizedSites) {
        this.errorTitle = 'Link unsuccessful';
        this.errorMessage =
          "You must select at least one Webflow site during the authentication process. Let's try again.";
      } else {
        const payload = new FormData();
        payload.append('readKey', read);
        this.deleteKeys(payload);
      }
      this.isLoading = false;
      this.step = 0;
    },
  },
};
</script>
