<template>
  <div class="wf-notification" @mouseover="isMouseOver = true" @mouseleave="isMouseOver = false">
    <div class="icon-wrapper">
      <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 19a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z" fill="#32C273" />
        <path d="m14.205 5.705 1.59 1.59L8.5 14.59l-4.295-4.294 1.59-1.59L8.5 11.41l5.705-5.705Z" fill="#fff" />
      </svg>
    </div>
    <div class="message">
      <div class="text">
        {{ notification.data.message }}
      </div>
      <div class="link">
        <a
          href="https://docs.google.com/document/d/1JjEhDugqVtvQ4pCGaXtM9CXvlT5g5vjlyIDcqIpjEGY/edit?pli=1#heading=h.qudj86ix33vr"
          target="_blank"
          class="flex align-items-center"
          @click="setNotification({ show: false })"
        >
          <div>Learn more</div>
        </a>
      </div>
      <button class="close-button" @click="setNotification({ show: false, data: {} })">
        <div class="icon icon--close"></div>
      </button>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  props: ['notification'],
  data() {
    return {
      isMouseOver: false,
    };
  },
  mounted() {
    this.notificationFadeOffLogic(5000);
  },
  methods: {
    ...mapMutations(['setNotification']),

    notificationFadeOffLogic(time) {
      setTimeout(() => {
        if (this.isMouseOver) {
          this.notificationFadeOffLogic(2000);
        } else {
          this.setNotification({ show: false, data: {} });
        }
      }, time);
    },
  },
};
</script>
