import { createStore } from 'vuex';
import * as oauthApi from '@/api/oauth-api';
import * as imageApi from '@/api/image-api';
import * as webflowApi from '@/api/webflow-api';
import * as syncDataApi from '@/api/sync-data-api';
import { dispatch } from '@/figma/ui-message-handler';
import { log } from '@/helpers/logger';

const preBuiltComponents = [
  { id: 'navbar-logo-left', name: 'Nav logo left', type: 'navigation', category: 'layout' },

  { id: 'hero-heading-left', name: 'Hero heading left', description: '', type: 'hero', category: 'layout' },
  { id: 'hero-heading-right', name: 'Hero heading right', description: '', type: 'hero', category: 'layout' },
  { id: 'hero-stack', name: 'Hero stack', description: '', type: 'hero', category: 'layout' },
  { id: 'hero-no-image', name: 'Hero no image', description: '', type: 'hero', category: 'layout' },

  { id: 'form-contact', name: 'Contact form', description: '', type: 'form', category: 'layout' },
  { id: 'form-newsletter', name: 'Newsletter form', description: '', type: 'form', category: 'layout' },

  { id: 'team-circles', name: 'Team Circles', description: '', type: 'team', category: 'layout' },
  { id: 'team-rectangles', name: 'Team Rectangles', description: '', type: 'team', category: 'layout' },

  { id: 'logos-quote-block', name: 'Logos Quote Block', description: '', type: 'logos', category: 'layout' },
  { id: 'logos-title', name: 'Logos Title', description: '', type: 'logos', category: 'layout' },

  { id: 'gallery-overview', name: 'Gallery Overview', description: '', type: 'gallery', category: 'layout' },
  { id: 'gallery-scroll', name: 'Gallery Scroll', description: '', type: 'gallery', category: 'layout' },

  { id: 'features-list', name: 'Features List', description: '', type: 'features', category: 'layout' },
  { id: 'features-metrics', name: 'Features Metrics', description: '', type: 'features', category: 'layout' },

  { id: 'pricing-comparison', name: 'Pricing Comparison', description: '', type: 'pricing', category: 'layout' },
  { id: 'pricing-items', name: 'Pricing Items', description: '', type: 'pricing', category: 'layout' },

  {
    id: 'testimonial-column-dark',
    name: 'Testimonial Column Dark',
    description: '',
    type: 'testimonials',
    category: 'layout',
  },
  {
    id: 'testimonial-column-light',
    name: 'Testimonial Column Light',
    description: '',
    type: 'testimonials',
    category: 'layout',
  },
  {
    id: 'testimonial-image-left',
    name: 'Testimonial Image Left',
    description: '',
    type: 'testimonials',
    category: 'layout',
  },
  { id: 'testimonial-stack', name: 'Testimonial Stack', description: '', type: 'testimonials', category: 'layout' },

  { id: 'footer-dark', name: 'Footer', description: '', type: 'footer', category: 'layout' },

  { id: 'new-page', name: 'New Page', description: '', type: 'structure', category: 'structure' },
  { id: 'section-container', name: 'Section + container', description: '', type: 'structure', category: 'structure' },
  { id: '2-col', name: '2 col', description: '', type: 'structure', category: 'structure' },
  { id: '3-col', name: '3 col', description: '', type: 'structure', category: 'structure' },
  { id: '4-col', name: '4 col', description: '', type: 'structure', category: 'structure' },
  { id: 'heading-3-col', name: 'Heading + 3 col', description: '', type: 'structure', category: 'structure' },
  { id: '2-by-2-grid', name: '2x2 grid', description: '', type: 'structure', category: 'structure' },
];

// eslint-disable-next-line no-restricted-syntax
export default createStore({
  modules: {},
  state: {
    user: null,
    token: null,
    userInfo: null,
    userSites: null,
    isSafari: false,
    figmaFileName: null,
    selectedSiteId: null,
    authorizationCode: null,

    clipboardData: null,
    needsToConfirmCopy: false,

    isLoggedIn: false,
    currentSelection: null,
    hasImagesToCopy: false,
    isFirstTimeOnPlugin: true,
    copiedSuccessfully: false,
    isCopyButtonLoading: false,
    hasReadDesignSystemNotification: false,

    textSizeUnit: 'px',
    remBaseValue: '16',
    cssClassPrefix: 'figma',
    variableNumberUnit: 'px',

    showNotificationsNumber: null,
    modal: { show: false, data: {} },
    warning: { type: null, data: {} },
    notification: { show: false, data: {} },
    currentPage: { name: 'home', params: {} },
    banner: {
      show: false,
      message: 'Safari not supported. Use Webflow in Chrome.',
    },

    // New Layout
    preBuiltComponents,
    isScrolling: false, // To add shadow to Layout sticky header
    selectedTab: 'copy-paste',
    designSystemSyncSelectedTab: 'components', // components || variables

    variablesToSync: [],
    componentsToSync: [],
    syncedSuccessfully: false,
  },
  getters: {
    isAdmin(state) {
      // TODO vulnerability?
      return (state.user && state.user.email === 'john@makers.so') ||
        (state.user && state.user.email === 'bryant@webflow.com') ||
        (state.user && state.user.email === 'joao.damaia@webflow.com') ||
        (state.user && state.user.email === 'labs@webflow.com')
        ? true
        : false;
    },
    isWebflowUser(state) {
      return state.user && state.user.email.endsWith('@webflow.com');
    },
    selectedSiteShortName(state) {
      return state.userSites.find((site) => site.id === state.selectedSiteId)?.shortName ?? '';
    },
    selectedSiteName(state) {
      return state.userSites.find((site) => site.id === state.selectedSiteId)?.displayName ?? '';
    },
    webflowClientId() {
      return import.meta.env.VITE_WEBFLOW_CLIENT_ID;
    },
    selectedWebflowSiteUrlWithApp(state, getters) {
      return `https://${getters.selectedSiteShortName}.design.webflow.com/?app=${getters.webflowClientId}`;
    },
    selectedWebflowSiteUrl(state, getters) {
      return `https://${getters.selectedSiteShortName}.design.webflow.com`;
    },
  },
  actions: {
    // Images
    async uploadImagesToS3Bucket(_, data) {
      return await imageApi.uploadImagesToS3Bucket(data).then((response) => {
        return response.data;
      });
    },
    async uploadImageToS3Bucket(_, data) {
      return await imageApi.uploadImageToS3Bucket(data).then((response) => {
        return response.data;
      });
    },
    async signFile(_, data) {
      return await imageApi.signFile(data).then((response) => {
        return response.data;
      });
    },

    // Authentication
    async generateKeys() {
      return await oauthApi
        .generateKeys()
        .then((response) => {
          return response.data;
        })
        .catch((error) => log(error));
    },
    async getAuthenticatedUser(_, data) {
      return await oauthApi
        .getAuthenticatedUser(data)
        .then((response) => {
          return response.data;
        })
        .catch((error) => log(error));
    },
    async getUserData(_, data) {
      return await oauthApi
        .getUserData(data)
        .then((response) => {
          return response.data;
        })
        .catch((error) => log(error));
    },
    async logUserOut(_, data) {
      return await oauthApi
        .logUserOut(data)
        .then((response) => {
          return response.data;
        })
        .catch((error) => log(error));
    },
    async deleteKeys(_, data) {
      return await oauthApi
        .deleteKeys(data)
        .then((response) => {
          return response.data;
        })
        .catch((error) => log(error));
    },

    // Webflow API
    async getCode(_, data) {
      return await webflowApi
        .getCode(data)
        .then((response) => {
          return response.data;
        })
        .catch((error) => log(error));
    },
    async getAccessToken(_, data) {
      return await webflowApi
        .getAccessToken(data)
        .then((response) => {
          return response.data;
        })
        .catch((error) => log(error));
    },
    async getUser(_, data) {
      return await webflowApi
        .getUser(data)
        .then((response) => {
          return response.data;
        })
        .catch((error) => log(error));
    },
    async getInfo(_, data) {
      return await webflowApi
        .getInfo(data)
        .then((response) => {
          return response.data;
        })
        .catch((error) => log(error));
    },
    async revokeToken(_, data) {
      return await webflowApi.revokeToken(data).then((response) => {
        return JSON.parse(response.data);
      });
    },

    // Pre Built Components
    async storePreBuiltComponentJson(_, data) {
      return await webflowApi.storePreBuiltComponentJson(data).then((response) => {
        return response;
      });
    },

    // Sync Data
    async createSyncData(_, data) {
      return await syncDataApi.createSyncData(data).then((response) => {
        return response;
      });
    },

    async updateSelectedSite({ commit }, { site }) {
      commit('setSelectedSiteId', site.id);
      await dispatch('setRootPropertyValue', { prop: 'selectedSiteId', value: site.id });
    },
  },
  mutations: {
    setSelectedTab(state, value) {
      state.selectedTab = value;
    },

    setCurrentPage(state, value) {
      state.currentPage = value;
    },
    setUser(state, value) {
      state.user = value;
    },
    setToken(state, value) {
      state.token = value;
    },
    setIsSafari(state, value) {
      state.isSafari = value;
    },
    setUserInfo(state, value) {
      state.userInfo = value;
    },
    setUserSites(state, value) {
      state.userSites = value;
    },
    setSelectedSiteId(state, value) {
      state.selectedSiteId = value;
    },
    setClipboardData(state, value) {
      state.clipboardData = value;
    },
    setNeedsToConfirmCopy(state, value) {
      state.needsToConfirmCopy = value;
    },
    setHasImagesToCopy(state, value) {
      state.hasImagesToCopy = value;
    },
    setIsFirstTimeOnPlugin(state, value) {
      state.isFirstTimeOnPlugin = value;
    },
    setHasReadDesignSystemNotification(state, value) {
      state.hasReadDesignSystemNotification = value;
    },
    setAuthorizationCode(state, value) {
      state.authorizationCode = value;
    },
    setIsLoggedIn(state, value) {
      state.isLoggedIn = value;
    },
    setCurrentSelection(state, value) {
      state.currentSelection = value;
    },
    setCopyButtonLoading(state, value) {
      state.isCopyButtonLoading = value;
    },
    setCopiedSuccessfully(state, value) {
      state.copiedSuccessfully = value;
    },
    setModal(state, value) {
      state.modal = value;
    },
    setNotification(state, value) {
      state.notification = value;
    },
    setBannerVisibility(state, value) {
      state.banner.show = value;
    },
    setWarning(state, value) {
      state.warning = value;
    },
    setShowNotificationsNumber(state, value) {
      state.showNotificationsNumber = value;
    },
    setCssClassPrefix(state, value) {
      state.cssClassPrefix = value;
    },
    setTextSizeUnit(state, value) {
      state.textSizeUnit = value;
    },
    setVariableNumberUnit(state, value) {
      state.variableNumberUnit = value;
    },
    setRemBaseValue(state, value) {
      state.remBaseValue = value;
    },
    setComponentsToSync(state, value) {
      state.componentsToSync = value;
    },
    setVariablesToSync(state, value) {
      state.variablesToSync = value;
    },
    setSyncedSuccessfully(state, value) {
      state.syncedSuccessfully = value;
    },
    setIsScrolling(state, value) {
      state.isScrolling = value;
    },
    setDesignSystemSyncSelectedTab(state, value) {
      state.designSystemSyncSelectedTab = value;
    },
    setFigmaFileName(state, value) {
      state.figmaFileName = value;
    },
    setWriteKey(state, value) {
      state.writeKey = value;
    },
  },
});
