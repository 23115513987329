<template>
  <section class="wf-set-html-tag-section wf-set-responsiveness">
    <div class="tooltip-wrapper">
      <div class="wf-tooltip-wrapper">
        <div class="type type--small"><strong>Set responsiveness</strong></div>
        <div class="icon-wrapper flex-center" id="responsiveTooltip">
          <WfIcon icon="question-mark" />
        </div>
      </div>
    </div>
    <div class="wf-html-tag-selector">
      <button
        class="selection-item"
        @click="setCurrentOption(tag)"
        v-for="(tag, index) in responsiveOptions"
        :key="index"
        :class="{ 'selection-item--active': currentOptionId === tag.id }"
      >
        {{ tag.display }}
      </button>
    </div>
  </section>
</template>

<script>
import tippy from 'tippy.js';
import { mapState } from 'vuex';
import 'tippy.js/dist/tippy.css';
import { dispatch } from '@/figma/ui-message-handler';

import WfIcon from '@/components/WfIcon.vue';

export default {
  components: {
    WfIcon,
  },
  data() {
    return {
      isOpen: false,
      currentOptionId: 'no',
      responsiveOptions: [
        { id: 'desktop', display: 'Tablet', wfComponent: 'MediumColumns' },
        { id: 'tablet', display: 'Mobile Landscape', wfComponent: 'SmallColumns' },
        { id: 'phone', display: 'Mobile Portrait', wfComponent: 'TinyColumns' },
        { id: 'no', display: 'Not Responsive', wfComponent: '' },
      ],
    };
  },
  mounted() {
    this.currentOptionId = this.getResponsiveOption(this.currentSelection);
    this.setupTooltips();
  },
  watch: {
    currentSelection(newVal) {
      this.currentOptionId = this.getResponsiveOption(newVal);
    },
  },
  computed: {
    ...mapState(['currentSelection']),
    currentOptionName() {
      return this.responsiveOptions.find((o) => o.id === this.currentOptionId).display;
    },
  },
  methods: {
    setCurrentOption(option) {
      this.currentOptionId = option.id;
      const payload = { id: this.currentSelection.id, key: 'wfComponent', value: option.wfComponent };
      dispatch('updateNode', payload);
      this.isOpen = false;
    },
    getResponsiveOption(node) {
      switch (node.wfComponent) {
        case 'MediumColumns':
          return 'desktop';
        case 'SmallColumns':
          return 'tablet';
        case 'TinyColumns':
          return 'phone';
        default:
          return 'no';
      }
    },
    setupTooltips() {
      tippy('#responsiveTooltip', {
        maxWidth: 200,
        theme: 'custom',
        content: 'Specify which breakpoint size you want the selected auto layout to shift to a vertical stack.',
      });
    },
  },
};
</script>
