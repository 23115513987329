<template>
  <div class="wf-cta-section wf-success-screen-buttons button-wrapper buttons-group">
    <button class="button button--outline flex-center" @click="setSyncedSuccessfully(false)">
      <WfIcon icon="back-arrow" />
      Back
    </button>
    <a
      target="_blank"
      :href="selectedWebflowSiteUrlWithApp"
      class="button button--primary flex-center"
      @click="setSyncedSuccessfully(false)"
    >
      Continue in Webflow
      <WfIcon icon="tab-new" color="#FFF" />
    </a>
  </div>
</template>
<script>
import { mapMutations, mapGetters } from 'vuex';

import WfIcon from './WfIcon.vue';

export default {
  components: {
    WfIcon,
  },
  computed: {
    ...mapGetters(['selectedWebflowSiteUrlWithApp']),
  },
  methods: {
    ...mapMutations(['setSyncedSuccessfully']),
  },
};
</script>
